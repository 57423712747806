@define-extend login-field {
  width: 340px;
}

.modal .alert-modal {
  max-height: max-content;
  max-width: max-content;
  margin: 80px auto;
  padding: 60px 10px 32px 10px;
  width: 100%;
  background-color: #ffffff;
  position: relative;

  @media (--device) {
    margin: 80px auto;
    padding: 46px 5px 32px 5px;
  }

  &__title {
    text-align: center;
    margin-bottom: 30px;
    font-size: 26px;
    font-weight: 700;
  }

  &__text {
    text-align: center;
    word-break: break-all;
    overflow: hidden;
    display: block;
  }

  &__contact-email {
    color: #ec6262;
  }

  &__field {
    @extend login-field;

    &--button {
      @extend login-field;
      max-width: 481px;

      .login-modal__button-signup {
        background-color: #f5f5f5;
        color: #575252;
      }

      @media (--device) {
        width: 300px;
      }
    }

    @media (--device) {
      width: 300px;
    }
  }

  p {
    height: 100%;
  }
  img {
    object-fit: contain;
  }

  h3,
  h4 {
    color: #565252;
  }

  &__button {
    width: 100%;
    margin: 20px auto;

    &--grey {
      background-color: #e6e6e6;
      margin-bottom: 17px !important;
      width: 100%;
      margin: 0;

      &:hover {
        box-shadow: 0px 0px 1px 1px #e6e6e6;
      }
    }
  }

  &__close {
    color: #565252;
    position: absolute;
    right: 7%;
    transform: scale(1, 0.9);
    top: 20px;
    font-size: 18px;
    font-weight: 700;
    cursor: pointer;
  }
}
