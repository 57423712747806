@define-extend card-base {
  height: 100%;
}

@define-extend card-container {
  position: relative;
  transform: translateY(-50%);
  top: 50%;
  text-align: center;
}

.card-status {
  @extend card-base;

  &__title {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 20px;
  }
  
  &__container {
    @extend card-container;
  }
  
  &__description {
    display: block;
    width: 95%;
    margin-top: 4px;
    font-size: 10px;
    font-weight: 700;
  }
  
  &--warning {
    @extend card-base;
    background-color: #f1ebd9;
    color: #cdb04c;
    width: 100%;
  }

  &--ok {
    @extend card-base;
    background-color: #d8ebdb;
    color: #168a29;
    width: 100%;
  }

  &--denied {
    @extend card-base;
    background-color: #ff6666;
    & .card-status__title,
    .card-status__description {
      color: white;
    }
    width: 100%;
  }

  &__icon {
    margin: 7px;
  }
}
