.simplecard {
  background-color: #f5f5f5;
  width: 100%;
  /* max-width: 500px; */
  /* padding: 70px 60px; */
  margin-left: 28px;
  display: inline-block;
  vertical-align: top;
  @media (--device) {
    width: 100%;
    margin-top: 20px;
  }
  @media (--phone) {
    margin-left: 0;
  }
  &__title {
    display: block;
    margin-bottom: 5px;
    text-align: center;
    font-size: 18px;
    color: #575252;
  }
  &__value {
    display: block;
    color: $title;
    text-align: center;
    font-size: 36px;
    font-weight: 900;
  }
}
