.done-step {
  text-align: center;
  width: 55%;
  margin-left: 50%;
  @media (--device) {
    margin-left: 3%;
    width: 95%;
    font-size: 14px;
  }
  &__button {
    display: block !important;
    width: 230px;
    height: 52px;
    margin: 10px auto;
    font-weight: 400 !important;
  }
  &__button img {
    display: block;
    float: right;
    margin-top: 18px;
  }
}
