.card-student-detail {
  &__wrapper {
    max-width: 900px;
  }

  &__item {
    padding: 10px 0;
    margin-bottom: 30px;

    &_header {
      border-bottom: 1px solid #c1c1c1;
      padding-bottom: 20px;
      margin-bottom: 1rem;
    }
  }

  &__student_name {
    text-align: left;
    font: Bold 22px/27px Lato;
    letter-spacing: 0;
    color: #575252;
    margin-bottom: 10px;
  }

  &__school_name,
  &__voucher_name {
    text-align: left;
    font: Bold 16px/19px Lato;
    letter-spacing: 0;
    color: #707070;
  }

  &__show {
    float: right;
    text-decoration: underline;
    font-weight: bold;
    margin-top: -14px;

    &__more {
      &:after {
        content: '';
        background-image: url('../components/CardStudent/images/down_arrow_red.svg');
        width: 9px;
        height: 6px;
        display: inline-block;
        margin: 0 0 1px 10px;
      }
    }

    &__less {
      &:after {
        content: '';
        background-image: url('../components/CardStudent/images/up_arrow_red.svg');
        width: 9px;
        height: 6px;
        display: inline-block;
        margin: 0 0 1px 10px;
      }
    }

    @media (--phone) {
      float: none;
      display: block;
      text-align: center;
      margin-top: 10px;
    }

    a {
      color: #ec6262;
    }
  }
}
