.faq .faqcontact {
  &__form {
    max-width: 540px;
    padding: 0;
  }
  &__title {
    text-align: center;
    margin-bottom: 30px;
  }
  &__button {
    width: 144px;
    &.cancel {
      margin-right: 5px;
    }
  }
  &__buttons {
    text-align: right;
    @media (--phone) {
      max-width: 347px;
      margin: 0 auto;
    }
  }
  &__msg {
    color: $title;
    margin-bottom: 5px;
  }
  .field {
    padding: 0;
  }
}
