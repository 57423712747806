@define-extend identification__button {
  display: inline-block;
  width: 120px;
  background-color: $main;
  font-size: 18px;
  font-weight: 400;
  color: #fff;
  padding: 37px 0;
  margin-right: 19px;
  border: 0;
  border-radius: 5px;
  cursor: pointer;
}

.identification {
  text-align: center;
  &__subtitle {
    margin: 7px 0 20px 0;
  }

  &__button {
    &--register,
    &--login {
      @extend identification__button;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
