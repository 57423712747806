.login {
  max-width: 490px;
  margin: 0 auto;
  &__form {
    .FormInput {
      max-width: 358px;
    }
  }
  &__title {
    margin: 0;
  }
  &__subtitle {
    margin-bottom: 30px;
  }
  &__forgot {
    margin: -25px 8px 25px 0px;
    text-align: right;
    @media (--device) {
      margin: -25px 8px 25px 0px;
      text-align: center;
    }
    cursor: pointer;
  }
  &__buttons {
    text-align: right;
    @media (--device) {
      text-align: center;
    }
  }
  &__button {
    width: 144px;
    margin-right: 10px;
    &:last-child {
      margin-right: 0;
    }
  }
}
