.educational-solutions {
  display: inline-flex;
  flex-direction: column;
  margin-bottom: 40px;
  input {
    position: absolute;
    right: -30px;
  }

  &__images {
    position: relative;
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
    & img {
      width: 100px;
      margin: 0 -30px;
      box-shadow: -1px 0px 2px 0px grey;
    }
  }

  &__description {
    font-weight: bold;
    color: rgb(87, 82, 82);
    & > * {
      margin-bottom: 5px;
    }
    & > :last-child {
      font-size: 20px;
    }
  }

  &__omitted-quantity {
    position: absolute;
    right: -30px;
    bottom: 0px;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    display: flex;
    background-color: #ec6262;
    color: white;
    font-weight: 700;
    font-size: 16px;
    border-radius: 50%;
  }
}
