.reasons {
  &__container {
    display: block;
    margin: 10px;
    padding: 10px;
    border-radius: 5px;
    background-color: #fff4cf;
    p,
    li {
      font-size: 14px;
      color: #000000;
    }
  }
}
