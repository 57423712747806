@define-extend button {
  display: inline-block;
  cursor: pointer;
  font-size: 18px;
  font-weight: 100;
  color: #fff;
  text-align: center;
  background-color: $main;
  border-radius: 5px;
  border: 0;
  padding: 0 15px;
  transition: 0.3s linear;
}

.copy_to_clipboard_text {
  border: none;
  color: #0000ee;
  font-size: 18px;
  word-break: break-all;
  text-align: center;
  text-decoration: underline;
  cursor: pointer;
  margin-bottom: 15px;
  display: block;
}

.button {
  @extend button;

  &.button-not-filled {
    background-color: transparent;
    color: #575252;
    border: 2px #575252 solid;
  }

  @media (--device) {
    display: block;
    width: 120px;
    margin: 20px auto;
  }

  &__sync {
    @extend button;
    width: 105px;
    background: url('../components/ReportTable/images/update-arrows.png') #6aabd6 no-repeat 9% center;
    line-height: 28px;
    font-size: 12px;
    text-align: right;
  }

  &__update {
    @extend button;
    width: 80px;
    background: #6aabd6;
    line-height: 28px;
    font-size: 12px;
    text-align: center;
    float: right;

    &:hover {
      color: #fff;
    }
  }

  &__upload {
    @extend button;
    width: 158px;
    background: url('../components/SchoolDetail/images/upload.png') $main no-repeat 9% center;
    line-height: 28px;
    font-size: 12px;
    text-align: right;
  }

  &__loadmore {
    @extend button;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    font-size: 25px;

    &:hover {
      &:active {
        opacity: 0.8;
      }

      &:hover {
        box-shadow: 0px 0px 1px 1px $main;
      }
    }
  }

  &__normal {
    @extend button;
    line-height: 50px;

    &:active {
      opacity: 0.8;
    }

    &:hover {
      box-shadow: 0px 0px 1px 1px $main;
    }

    &:disabled {
      opacity: 0.8;
    }

    &--modal-confirm {
      @extend button;
      display: block;
      width: 100%;
      margin: 10px 0;
      height: 40px;
    }

    &--modal-cancel {
      @extend button;
      display: block;
      width: 100%;
      margin: 10px 0;
      background-color: $lightGray;
      height: 40px;
    }

    &--cancel {
      @extend button;
      line-height: 50px;
      background-color: $lightGray;

      &:active {
        opacity: 0.8;
      }

      &:hover {
        box-shadow: 0px 0px 1px 1px $lightGray;
      }
    }
  }

  &__medium {
    @extend button;
    line-height: 40px;

    &:active {
      opacity: 0.8;
    }
  }

  &__small {
    @extend button;
    line-height: 28px;
    font-size: 12px;

    &:active {
      opacity: 0.8;
    }
  }

  &__big {
    @extend button;
    width: 120px;
    line-height: 60px;

    &:active {
      opacity: 0.8;
    }

    &:hover {
      box-shadow: 0px 0px 1px 1px $main;
    }
  }

  &:focus {
    opacity: 0.8;
  }

  &:disabled {
    background-color: #e6e6e6;
    box-shadow: 0 #e6e6e6;
  }
}

@define-extend field {
  display: block;
  overflow: hidden;
  margin: 0 auto 20px auto;
}

.field {
  @extend field;

  @media (--phone) {
    padding: 0 10px;
    max-width: 358px;
  }

  &--half {
    @extend field;

    .FormInput {
      width: 44%;
      margin-right: 2%;
      float: left;
    }

    .field__label--bottom {
      float: left;
      text-align: right;
      margin-top: 45px;
      font-size: 14px;
      color: $title;
    }

    &:after {
      content: '';
      display: block;
      clear: both;
    }
  }

  &--loader {
    display: flex;
    align-items: center;

    .field__label {
      margin-top: 0;
    }
  }

  &--right {
    @extend field;
    width: 100%;
    max-width: 358px;
    float: right;
    margin-bottom: 0;

    @media (--device) {
      max-width: 100%;
    }
  }

  &--left {
    @extend field;
    width: 100%;
    float: left;
    margin-left: 11%;
    margin-bottom: 0;

    @media (--device) {
      max-width: 100%;
    }

    @media (--phone) {
      margin-left: 0;
    }
  }

  &__label {
    float: left;
    margin-top: 18px;
    font-size: 16px;
    color: #575252;

    @media (--phone) {
      margin-top: 0;
      margin-bottom: 5px;
    }
  }

  &__text {
    width: 100%;
    background: #fff;
    line-height: 60px;
    border: 1px $title solid;
    border-radius: 5px;
    padding: 0 10px;
    font-size: 16px;
    float: left;
    margin-bottom: 5px;

    &::-webkit-input-placeholder {
      color: $title;
    }
  }

  &__textarea {
    @extend .field__text;
    padding: 10px;
    min-height: 140px;
    line-height: 130%;
    resize: none;
  }

  &__select {
    overflow: hidden;

    @media (--phone) {
      width: 100%;
    }

    .FormInput {
      width: 100%;
      max-width: 358px;
      line-height: 60px;
      margin-bottom: 5px;
      background: url('../components/RegisterForm/images/icon_expand.png') no-repeat 96% center #fff;
      overflow: hidden;
      float: left;
      border: 1px $title solid;
      border-radius: 5px;

      &.-hasError {
        background: url('../components/RegisterForm/images/icon_expand_error.png') no-repeat 96% center #fff;
        border: 1px $main solid;

        select {
          color: $main;
        }
      }

      .FormError {
        display: none !important;
      }
    }

    select {
      height: 60px;
      background: transparent;
      border: none;
      font-size: 14px;
      width: 110%;
      color: $title;
    }
  }
  &__school-search {
    overflow: initial !important;
    height: max-content;
  }
  &__radio {
    width: 100%;
    float: left;
    margin-bottom: 8px;
    padding-top: 17px;

    &__option {
      &:last-child {
        margin-left: 28px;
      }
    }

    &__label {
      font-size: 16px;
      color: $title;
      margin: 0 5px;
    }
  }

  &__checkbox {
    width: 19px;
    height: 19px;
    appearance: none;
    background-color: #fff;
    border: 1px solid $title;
    border-radius: 3px;
    text-align: left;

    &:checked {
      color: #ffffff;
      border: 0;
      background: url('../components/SelectProducts/images/mark.png') no-repeat center;
      background-size: cover;
    }
  }

  &__date {
    z-index: 2;
  }

  &__terms {
    .FormInput.-hasError+.field__checkbox__label {
      color: $main;
    }
  }

  &__divider {
    display: block;
    font-size: 20px;
    text-align: right;
    color: $title;
    margin-bottom: 14px;

    @media (--phone) {
      max-width: 336px;
      margin: 10px auto;
      text-align: left;
    }
  }

  &__error {
    display: inline-block;
    text-align: left;
    width: 100%;
    color: $main;
  }

  /* Default styles used by react-form */
  .FormInput {
    width: 100%;
    /*max-width: 358px;*/
    display: inline-block;
    text-align: left;

    &.-hasError {

      .field__text,
      .field__textarea {
        border: 1px $main solid;
      }
    }
  }
}

.FormInput.-hasError {

  [type='radio']:checked+span:before,
  [type='radio']:not(:checked)+span:before {
    border: 2px solid $main;
  }

  .field__radio__label {
    color: $main;
  }
}

[type='radio']:checked,
[type='radio']:not(:checked) {
  position: absolute;
  left: -9999px;
}

[type='radio']:checked+span,
[type='radio']:not(:checked)+span {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
}

[type='radio']:checked+span:before,
[type='radio']:not(:checked)+span:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 22px;
  height: 22px;
  border: 2px solid $title;
  border-radius: 100%;
  background: #fff;
}

[type='radio']:checked+span:after,
[type='radio']:not(:checked)+span:after {
  content: '';
  width: 12px;
  height: 12px;
  background: $main;
  position: absolute;
  top: 5px;
  left: 5px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

[type='radio']:not(:checked)+span:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

[type='radio']:checked+span:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

/* Default styles used by react-form */
.FormError {
  text-align: left;
  color: #ec6262;
  font-size: 14px;
}

.field__password {
  position: relative;

  input {
    padding-right: 45px;
  }

  .btn_visibility_password {
    position: absolute;
    width: 30px;
    height: 30px;
    top: 17px;
    right: 10px;
    border: none;
    background-color: transparent;
    padding: 0;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
}

.field__select_state {
  select {
    width: 100%;
    background: #fff;
    border: 1px #575252 solid;
    border-radius: 5px;
    padding: 0 10px;
    font-size: 16px;
    margin-bottom: 5px;
    height: 62px;
  }
}

.field__uf_input_group {

  margin: 20px 0;

  &>* {
    display: flex;
    width: 160px;
    justify-content: space-between;
    margin: 10px 0;
    align-items: center;
  }
}