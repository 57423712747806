.instructions {
  margin-top: 80px;
  lost-center: 1024px;
  padding: 0 20px;

  &__title {
    text-align: center;
    font-size: 32px;
    @media (--device) {
      font-size: 26px;
    }
  }

  &__list {
    margin-top: 85px;
    @media (--device) {
      margin-top: 40px;
    }
    &__title {
      display: inline-block;
      text-align: center;
      vertical-align: top;
      margin: 19px 0;
      @media (--device) {
        font-size: 24px;
        margin: 14px 0;
      }
    }
  }

  &__position {
    display: inline-block;
    vertical-align: top;
    width: 50px;
    line-height: 50px;
    margin: 10px 25px 0 0;
    border-radius: 50%;
    text-align: center;
    color: #fff;
    font-size: 20px;
    background-color: $main;
    @media (--device) {
      width: 30px;
      line-height: 30px;
      margin: 10px 13px 0 0;
    }
  }

  &__body {
    background: url('../components/Instructions/images/border_instructions.png') no-repeat;
    background-position: 23px 10px;
    lost-utility: clearfix;
    padding: 70px 0;
    @media (--device) {
      background-position: 13px 0px;
      padding: 40px 0;
    }
  }

  &__image {
    display: inline-block;
    margin: 0 55px 0 100px;
    @media (--device) {
      margin: 0 0 0 50px;
    }
    img {
      max-width: 100%;
      object-fit: fill;
    }
  }

  &__text {
    max-width: 416px;
    display: inline-block;
    vertical-align: bottom;
    margin: 9px 0;
    @media (--device) {
      margin-left: 50px;
    }
  }

  &__help {
    display: block;
    width: 100%;
    max-width: 423px;
    margin: 80px auto;
    padding: 12px 0;
    border: 3px $title solid;
    border-radius: 5px;
    color: $main;
    font-size: 16px;
    text-align: center;
    transition: border 0.4s linear;
    &:hover {
      color: $main;
      border: 3px $main solid;
    }
    @media (--device) {
      padding: 20px 0;
      font-size: 12px;
    }
  }
}
