.inline-loader {
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  display: none;
  &__container {
    display: inline-block;
    height: 100%;
  }
  &__box {
    display: inline-block;
    margin-left: 16px;
    width: 15px;
    height: 15px;
    position: relative;
    position: relative;
    top: 40%;
    transform: translateY(-50%);
    border: 2px solid $main;
    animation: cssload-loader 2.3s infinite ease;
    z-index: 5;
  }

  &__inner {
    vertical-align: top;
    display: inline-block;
    width: 100%;
    background-color: $main;
    animation: cssload-loader-inner 2.3s infinite ease-in;
  }
  &.active {
    display: block;
  }
}

@keyframes cssload-loader {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(180deg);
  }

  50% {
    transform: rotate(180deg);
  }

  75% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes cssload-loader-inner {
  0% {
    height: 0%;
  }

  25% {
    height: 0%;
  }

  50% {
    height: 100%;
  }

  75% {
    height: 100%;
  }

  100% {
    height: 0%;
  }
}
