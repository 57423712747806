.contract {
  margin: 30px;
  &__title {
    font-size: 32px;
    color: #575252;
    font-weight: 700;
    margin: 20px 0;
  }
  &__content {
    margin-top: 20px;
  }
}

.contract-card {
  display: inline-block;
  margin: 13px 34px;
  background-color: #f5f5f5;
  max-height: 170px;
  max-width: 202px;
  padding: 20px;
  width: 202px;
  &__date {
    display: block;
    text-align: center;
    font-size: 16px;
    color: #575252;
  }
  &__icon {
    display: block;
    margin: 10px auto;
  }
  &__school {
    display: block;
    font-size: 16px;
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #575252;
    text-align: center;
    height: 36px;
  }
  &__footer {
    cursor: pointer;
    margin-top: 10px;
    text-align: center;
  }
  &__link {
    font-size: 12px;
    color: #575252;
  }
  &__link-icon {
    vertical-align: middle;
    margin-left: 10px;
  }
}
