.card-request {
  display: block;
  position: relative;
  margin: 30px;
  background-color: #f5f5f5;
  max-width: 490px;
  height: 128px;
  box-shadow: none;
  transition: box-shadow 0.1s linear;
  @media (--phone) {
    max-width: 287px;
    width: 100%;
    margin: 20px auto;
    height: 200px;
  }

  @media (--tablet) {
    height: 146px;
  }

  &:hover {
    box-shadow: 0px 2px 16px #888888;
    transition: box-shadow 0.1s linear;
  }

  p {
    margin-bottom: 0;
  }

  &__order-container {
    width: 100%;
    overflow: hidden;
    display: inline-block;
    margin-left: 30px;
    margin-top: 30px;
    @media (--phone) {
      display: block;
      margin-left: 10px;
      margin-top: 20px;
    }
  }

  &__order-header {
    float: left;
    margin-right: 40px;
  }

  &__price-container {
    /* display: inline-block;
    vertical-align: top;
    margin-left: 20px;
    margin-top: 30px;
    @media (--phone) {
      display: block;
      margin: 10px 10px;
      margin-top: 20px;
    } */
  }

  &__status-container {
    height: 100%;
    width: 20%;
    float: right;
    @media (--phone) {
      width: 30%;
      text-align: center;
    }
  }

  &__text {
    display: block;
    font-size: 12px;
  }

  &__red-text {
    color: #ec6262;
    font-weight: 700;
    font-size: 20px;
    margin-top: 30px;
    @media (--phone) {
      font-size: 18px;
    }
  }

  &__buttons-container {
    width: 100%;
    margin-top: 5px;

    @media (--phone) {
      .button.bg-darkgray {
        font-size: 11px;
      }
      display: inline-block;
    }
  }

  .button {
    display: inline-block;
    font-size: 12px;
    height: 33px;
    width: 100px;
    padding: 5px;
    margin-right: 5px;
    margin-top: 5px;
    margin-bottom: 10px;
    @media (--phone) {
      font-size: 12px;
      height: 33px;
      margin: 5px auto;
      margin-right: 2px;
      width: 90px;
    }
  }

  &__helper {
    display: inline-block;
    width: 385px;
    @media (--phone) {
      display: inline-block;
      width: 196px;
    }
  }

  .cancel {
    vertical-align: top;
    background-color: #dbdbdb;
    /* float:right; */
  }

  &__date {
    font-size: 10px;
  }

  &__order {
    display: block;
  }

  &__footer {
    display: block;
    margin-top: 3px;
  }

  &__printer-text {
    font-size: 12px;
    margin-left: 10px;
    @media (--desktop) {
      display: none;
    }
  }

  &__printer-icon {
    margin-left: 12px;
    vertical-align: middle;
    @media (--desktop) {
      display: none;
    }
  }
}
