.admin-form {
  &__form {
    .field.disabled {
      opacity: 0.3;
      pointer-events: none;
    }

    .field__address_search {
      overflow: visible;
    }

    .field__address_search .fa {
      font-size: 3.8rem;
      margin-top: 1rem;
    }

    .field__address_search.incomplete .fa {
      color: #ec6262;
    }

    .field__address_search.found .fa {
      color: #3ee14e;
    }

    .field__address_search .found_address {
      width: 100%;
      display: inline-block;
      text-align: left;
      max-width: 358px;
      background-color: #3ee14e3d;
      padding: 1.3rem;
      border-radius: 5px;
      line-height: 2rem;
      position: relative;
      font-size: 1.55rem;
    }

    .modify_address {
      position: absolute;
      right: -3rem;
      background-color: #268a30;
      top: 0;
      height: 100%;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      color: #000 !important;
      width: 3rem;
      overflow: hidden;
    }

    .modify_address .fa {
      font-size: 1.7rem;
      position: absolute;
      left: 0.8rem;
      top: 2.5rem;
    }

    .field__address_search .FormInput {
      overflow: visible;
      position: relative;
    }

    .field__address_search .FormInput input {
      padding-left: 8rem;
    }

    .field__address_search .cep-search {
      position: relative;
    }

    .field.disabled .FormError {
      display: none !important;
    }

    .field__address_search .cep-search .FormInput + .FormError {
      margin-left: 11%;
    }

    .field__address_search .cep-search .FormInput.-hasError + .FormError {
      display: none !important;
    }

    .field__address_search .cep-search .open-correios {
      display: block;
      text-align: right;
      max-width: 468px;
    }

    .field__address_search .cep-search .open-correios:hover {
      text-decoration: underline;
    }

    .field__address_search .cep-search > span {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      top: 2rem;
      position: absolute;
      padding: 2rem;
      background-color: #ccc;
      z-index: 2;
      width: auto;
      margin: 0;
    }

    .field__address_search.incomplete .cep-search > span {
      background-color: #ec6262;
      color: #fff;
    }

    .field__address_search .loading {
      position: absolute;
      top: 1.5rem;
      right: -2.3rem;
      font-size: 0.8rem;
    }

    .field__address_search .loading .fa {
      font-size: 1.6rem;
      color: #666;
    }

    .field__perfil .field__radio__option {
      display: block;
      margin-left: 0 !important;
    }
  }
  &__terms {
    & > label {
      display: block;
      margin-bottom: 8px;

      @media (--phone) {
        display: inline-flex;
      }
    }

    a {
      color: #337ab7;
    }

    .FormInput {
      display: inline-block;
      vertical-align: top;
    }

    &__label {
      display: inline-block;
      vertical-align: top;
      color: $title !important;
      font-size: 14px;
      margin: 2px 0 0 5px;

      @media (--phone) {
        font-size: 12px;
      }
    }
  }
  &__cep-link {
    position: relative;
    top: -30px;
    left: 10px;
    @media (--phone) {
      top: 0px;
      left: 0px;
    }
  }

  .invalid-zip {
    margin-left: 10%;
    padding-left: 10px;
  }
}

.confirm-dialog {
  width: 430px !important;
  text-transform: uppercase;

  @media (--phone) {
    width: 100% !important;
  }

  .confirm-dialog__container {
    padding: 20px 10px;

    .confirm-dialog__message {
      padding: 15px 5px;
      font-size: 16px;
      line-height: 1.4;

      @media (--phone) {
        margin-bottom: 0px !important;
      }
    }
  }
}
