.confirmbooks {
  max-width: 817px;
  margin: 0 auto;
  font-family: 'Lato';
  margin-right: 30px;
  color: #575252;
  @media (--device) {
    padding: 0 20px;
    margin-right: auto;
  }
  &__header {
    margin-bottom: 50px;
    text-align: center;
  }

  &__title {
    font-size: 32px;
    font-weight: 700;
    margin: 0;
    margin-bottom: 7px;
  }

  &__subtitle {
    font-size: 20px;
    font-weight: 400;
    margin: 0;
  }

  &__buttons {
    text-align: center;
    margin-top: 50px;
  }

  &__backbutton.button {
    width: 148px;
    margin-right: 20px;
    @media (--device) {
      display: inline-block;
      width: 110px;
      font-size: 12px;
      display: inline-block;
      margin-right: 10px;
    }
    &:hover {
      box-shadow: none;
    }
  }

  &__confirmbutton.button {
    width: 120px;
    border: 2px solid #30e04e;
    background-color: #30e04e;
    @media (--device) {
      font-size: 14px;
      display: inline-block;
      vertical-align: top;
    }
    &:hover {
      box-shadow: none;
    }
  }
}

.confirmlist {
  font-size: 22px;
  &__item {
    overflow: hidden;
    border-bottom: 1px #ededed solid;
    padding: 21px 0;
    &:last-child {
      border-bottom: 0;
    }
  }

  &__editlink {
    float: right;
    font-size: 16px;
    font-weight: 400;
    color: #575252;
    @media (--device) {
      float: none;
    }
    img {
      width: 11px;
      vertical-align: middle;
      margin-right: 10px;
    }
  }

  &__itemcontainer {
    display: inline-block;
    vertical-align: top;
    font-weight: 700;
    span {
      color: #ec6262;
    }
  }
}
