.confirm-dialog.voucher {
  background-color: #f2dede;

  .voucher__errors {
    list-style: inherit;
    margin: 0 20px 20px;
    color: #a94442;

    &__field {
      font-weight: bold;
    }
  }
}

.adminvoucher {
  .admin-form {
    &__header {
      margin: 35px 0 10px;
    }

    &__title {
      &.subtitle {
        font-size: 20px;
        color: #ec6262;
        font-weight: bold;
      }
    }

    .field__voucher_serie select:disabled,
    .field__voucher_status select:disabled {
      background-color: #e4e4e4;
      font-style: italic;
    }
  }

  .admin-form h2 {
    display: block;
    clear: both;
    float: left;
    margin: 5px 0;
  }

  .admin-form h2:first-of-type {
    margin-top: 10px;
  }

  .schoolcard {
    max-width: 100%;

    &__title {
      margin-bottom: 0;
    }

    &__title.school-name {
      color: #575252;
      font-weight: bold;
      font-size: 16px;
      margin-bottom: 20px;
    }

    &__contact-information {
      margin-bottom: 20px;
    }
  }

  .report-table {
    &__text-cell {
      font-size: 13px;

      .book-image {
        height: 50px;
        margin-right: 10px;
      }

      &.added-book {
        color: rgb(141, 196, 150);
        margin-left: 15px;
      }
    }

    &__header-cell {
      font-weight: bold;
    }

    &__button-cell {
      height: inherit;

      .button {

        &__add-book,
        &__remove-book,
        &__status-book {
          font-size: 12px;
          line-height: 28px;
          font-weight: 500;

          &:before {
            content: '';
            display: inline-block;
            margin-right: 5px;
            vertical-align: middle;
            height: 10px;
            width: 10px;
          }
        }

        &__add-book {
          background: none;
          border: 2px solid #ec6262;
          border-radius: 5px;
          color: #ec6262;

          &:before {
            background-image: url('../components/EditVoucher/images/icone_adicionar.svg');
          }
        }

        &__remove-book {
          &:before {
            background-image: url('../components/EditVoucher/images/icone_excluir.svg');
            height: 7px;
            width: 7px;
          }
        }

        &__status-book {
          width: 70%;
          min-width: 110px;

          &.active {
            &:before {
              background-image: url('../components/EditVoucher/images/icone_desativar.svg');
              height: 8px;
              width: 14px;
            }
          }

          &.inactive {
            background: none;
            border: 2px solid #ec6262;
            border-radius: 5px;
            color: #ec6262;

            &:before {
              background-image: url('../components/EditVoucher/images/icone_ativar.svg');
              height: 9px;
              width: 14px;
            }
          }
        }
      }
    }
  }

  .filters {
    margin-bottom: 20px;

    &__item {
      width: 100%;

      .filterselect {
        margin-right: 30px;

        &__inputwrapper {
          max-width: 200px;
        }
      }

      .filtertext {
        margin-right: 30px;
      }
    }
  }

  .books__actions {
    margin: 30px 0 80px;
    text-align: right;
    width: 960px;

    .button__normal {
      line-height: 40px;

      &.cancel {
        background: none;
        border: 2px solid #ec6262;
        color: red;
        margin-right: 20px;
        padding: 0 30px;
      }

      &:disabled {
        background-color: #999;
      }
    }
  }
}

.discount-cell-input {
  .field__radio__option {
    display: block;
    margin-left: 0 !important;

    .FormInput {
      display: inline-block;
      width: 90%;

      input {
        width: 100%;
      }
    }
  }

  .field__radio__label {
    font-size: 13px;

    &:before {
      left: 1px;
      top: 3px;
      width: 18px;
      height: 18px;
    }

    &:after {
      top: 7px;
      width: 10px;
      height: 10px;
    }
  }
}