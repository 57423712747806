.card-suborder {
  background-color: #fff;
  min-height: 170px;
  transition: box-shadow 0.1s linear;
  border-radius: 5px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.16);
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;

  &__header {
    padding-top: 1em;
    padding-left: 1em;
    padding-right: 1em;
    display:flex;
    align-items: center;
  }

  &__body {
    padding: 1.5em;
    display: flex;
    font-size: 16px;
    border-bottom: solid 1px #ddd;
    @media (--phone) {
      flex-direction: column;
      padding: 1em;
      gap:12px;
      padding-bottom: 2em;
    }
  }
 
  &__footer {
    padding: 1em 2em;
    display: flex;
    border-top: solid 1px #ddd;
    flex-direction: column;
  }
  
  &__actions{
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}
.tracking-info{
  display: flex;
  @media (--phone) {
    flex-direction: column;
    p{
      font-size: 16px;
    }
  }
  .tracking-code{
    flex:1;

  }
}
.card-suborder-info{
  flex: 1;
  p{
    @media (--phone) {
      font-size: 16px !important;
    }
  }
}

.suborder-title {
  margin-bottom: 1.2em;
  flex: 1;
  color: #ec6262;
  font: Bold 20px/24px Lato;
}
.card-student-detail {
  background-color: #f5f5f5;
  min-height: 170px;
  transition: box-shadow 0.1s linear;
  border-radius: 5px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.16);
  padding: 20px 20px 0px;

  @media (--phone) {
    height: auto;
    padding: 10px 10px 0px;
  }

  &__container {
    margin: 20px 0;

    @media (--tablet) {
      margin: 20px auto;
    }

    @media (--phone) {
      margin: 20px auto;
    }
  }

  &__body {
    margin-bottom: 20px;
    position: relative;
  }

  &__sub_order_code {
    display: inline-block;
    font: Bold 20px/24px Lato;
    margin-right: 20px;
    color: #575252;
  }

  &__status_description {
    border-radius: 12px;
    padding: 5px 20px;
    font: Bold 11px/13px Lato;
    width: max-content;
    display: inline-block;
    position: absolute;
    right: 0;
    top: 0;
    height: auto;
  }

  &__special_discount {
    display: block;
    width: fit-content;
    color: #6a6b6a;
    padding: 0.3rem;
    margin: 5px 0 0 -2px;
    font-weight: bold;
    font-size: 1.2rem;
  }

  &:hover {
    box-shadow: 0px 2px 16px #888888;
    transition: box-shadow 0.1s linear;
  }

  &__header {
    display: block;
    text-transform: uppercase;
    color: #ec6262;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 10px;

    @media (--phone) {
      margin-bottom: 0;
      font-size: 12px;
    }
  }

  &__row {
    lost-row: 1/2;
  }

  &__value {
    font-size: 16px;
    color: #575252;

    @media (--phone) {
      font-size: 12px;
    }
  }

  &__column {
    display: flex;
    lost-column: 1/4;

    @media (--phone) {
      lost-column: 1;
      margin-bottom: 10px;
    }
  }

  &__value-book-line {
    font-size: 16px;
    font-weight: bold;
    color: #575252;

    @media (--phone) {
      font-size: 12px;
    }
  }

  &__column-delivery {
    lost-column: 1/4;

    @media (--phone) {
      lost-column: 1;
      margin-bottom: 10px;
    }
  }

  &__column-options {
    lost-column: 1/4;

    @media (--phone) {
      lost-column: 1;
      margin-bottom: 10px;
      text-align: center;
    }
  }

  &__column-school {
    lost-column: 1/5;
    margin: 20px 0 0 0;
    width: 148px;
    text-transform: capitalize;

    @media (--phone) {
      lost-column: 1;
      margin: 10px 0 0 10px;
    }
  }

  .card-student-detail__column-school {
    margin-right: 0;
  }

  &__column-wrapper {
    display: inline-block;
    width: 100%;

    /* lost-column: 4/5 0; */
    @media (--phone) {
      lost-column: 1;
      margin-top: 10px;
    }
  }

  &__button-wrapper {
    lost-column: 1/5 2;
    height: 100%;
    width: 111px;
    float: left;

    @media (--phone) {
      lost-column: 1;
      height: 10px;
      margin-left: 10px;
    }
  }

  &__column-printer {
    font-size: 12px;
    font-weight: 400;
    color: #575252;
    cursor: pointer;

    @media (--phone) {
      width: auto;
      margin: 20px 0;
    }

    &-text {
      background-color: #dbdbdb;
      border-radius: 5px;
      cursor: pointer;
      font-size: 14px;
      padding: 7px;

      &:before {
        content: '';
        background-image: url('../components/CardSubOrder/images/imprimir.png');
        display: inline-block;
        height: 15px;
        margin-right: 5px;
        vertical-align: middle;
        width: 16px;
      }
    }

    /* img {
      vertical-align: middle;
      margin-right: 10px;
    } */
  }

  &__sub_order_cancel {
    padding-right: 8px;
    text-align: right;
    a {
      text-decoration: none;
      position: relative;
      color: #575252;

      &:after {
        content: '';
        width: 100%;
        position: absolute;
        left: 0;
        bottom: -1px;
        border-width: 0 0 1px;
        border-style: solid;
      }
    }
  }

  .button__small {
    margin: 50px auto;
    font-size: 12px;
    max-width: 100px;
    width: 100%;

    @media (--phone) {
      margin: 0;
    }
  }

  &__switch-book-view {
    height: 40px;
    border-radius: 0px 0px 5px 5px;

    &.more {
      background: #ec6262;

      .label:after {
        content: '';
        background-image: url('../components/CardSubOrder/images/down_arrow_white.svg');
        width: 9px;
        height: 5px;
        display: inline-block;
        margin: 0 0 2px 10px;
      }
    }

    &.less {
      background: #575252;

      .label:after {
        content: '';
        background-image: url('../components/CardSubOrder/images/up_arrow_white.svg');
        width: 9px;
        height: 5px;
        display: inline-block;
        margin: 0 0 2px 10px;
      }
    }

    button {
      width: 100%;
      height: 100%;
      background: none;
      border: none;
      text-align: center;
      letter-spacing: 0;
      color: #ffffff;
    }
  }
}