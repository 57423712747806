@define-extend input-voucher {
  width: 100%;
  max-width: 358px;
  height: 57px;
  padding: 0 12px;
  border-radius: 5px;
  border: 1px solid grey;
  margin-right: 1%;
  color: #575252;
  margin-top: 4px;
  display: block;
  margin: 0 auto;
}

.voucher-form {
  max-width: 538px;
  margin: 0 auto;

  .special_discount__input {
    display: block !important;
    margin: 0 auto !important;
  }

  &__title {
    text-align: center;
    font-size: 28px;
    margin-bottom: 30px;
  }

  &__subtitle {
    text-align: center;
    font-size: 16px;
  }

  &__cancel {
    width: 162px;
    float: left;
    background-color: #575252;
    color: #fff;
    margin-left: 3px;

    &:hover {
      box-shadow: 0px 0px 1px 1px #666666;
    }

    @media (--device) {
      width: 80%;
      margin: 8px 10%;
    }
  }

  &__ok {
    @media (--device) {
      width: 80%;
    }

    @media (--tablet) {
      margin: 8px 10%;
    }
  }

  &__input {
    @extend input-voucher;
    margin-top: 30px;
    margin-bottom: 30px;

    &--error {
      @extend input-voucher;
      border-color: #ec6262;
      margin-top: 20px;
    }

    &::-webkit-input-placeholder {
      font-size: 16px;
      font-weight: 400;
      color: #575252;
    }

    @media (--device) {
      width: 100%;
      padding: 0 5px;
      left: 0;
    }
  }

  &__select {
    @extend input-voucher;
    margin: 30px auto !important;
    display: block !important;
    font-size: 1.7rem;

    &--error {
      @extend input-voucher;
      border-color: #ec6262;
      margin-top: 20px;
    }

    &::-webkit-input-placeholder {
      font-size: 16px;
      font-weight: 400;
      color: #575252;
    }

    @media (--device) {
      width: 100%;
      padding: 0 5px;
      left: 0;
    }
  }

  &__info-container {
    margin: 16px 0;
  }

  &__info-text {
    display: block;
    text-align: center;
    font-size: 12px;

    &--error {
      display: block;
      text-align: center;
      font-size: 12px;
      color: #ec6262;
      text-align: center;
      margin: 16px 0;
    }
  }

  &__voucher {
    text-align: center;
    padding: 20px;
    width: 60%;
    font-size: 12px;
  }

  &__footer {
    max-width: 363px;
    margin: 0 auto;
    margin-top: 26px;
    text-align: right;

    @media (--phone) {
      text-align: center;
    }
  }
}
