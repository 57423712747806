@define-extend footer {
  background: #575252;
  padding: 45px 40px;
}
.footer {
  @extend footer;
  &--disabled {
    @extend footer;
    display: none;
  }
  &__container {
    width: 100%;
    max-width: 1366px;
    margin: 0 auto;
    lost-utility: clearfix;
    & > div {
      lost-column: 1/5;
      @media (--device) {
        lost-column: 1/1;
        margin-bottom: 35px;
      }
    }
  }

  &__list {
    margin-top: 20px;
    li {
      display: inline-block;
      margin-right: 35px;
      &:last-child {
        margin-right: 0;
      }
    }
  }

  span,
  address {
    display: inline-block;
    font-size: 16px;
    line-height: 130%;
    color: #fff;
    font-style: normal;
  }

  &__link {
    color: #fff;
    font-size: 16px;
  }
}
