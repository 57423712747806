.upload-file-modal {
  .modal-dialog {
    transform: translate(0, -50%);
    top: 30%;
    margin: 0 auto;
  }

  .inline-loader__container {
    text-align: center;
    height: 24px;
    width: 24px;
  }

  &__anchor-button {
    cursor: pointer;
    text-align: center;
    text-decoration: underline;
  }

  &__message-container {
    margin: 20px 0 55px 0;
    text-align: center;
  }

  .inline-loader__box {
    width: 24px;
    height: 24px;
  }

  &__title {
    color: $main;
    font-size: 20px;
    margin: 0;
    text-align: center;
  }

  &__loader {
    margin: 20px 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  &__content {
    padding: 20px 40px;
  }

  &__button-container {
    margin: 40px 0;
  }

  &__submit {
    &:disabled {
      opacity: 0.7;
    }
  }

  &__button {
    float: none;
    background-color: #00a2e8 !important;
  }

  &__label {
    color: red !important;
    font-size: 1.25em;
    font-weight: 700;
    color: white;
    background-color: black;
    display: inline-block;
  }

  &__filename {
    font-weight: 700;
    margin-left: 8px;
  }

  &__input-file {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
}
