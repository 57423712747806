.ticketlist {
  max-width: 720px;
  &__title {
    margin-bottom: 23px;
  }

  &__button {
    margin-bottom: 20px;
  }

  .button__loadmore.ticketlist__loadmore {
    display: block;
    margin: 0 auto;
  }
}

.card-rectangle {
  max-width: 720px;
  padding: 20px 25px;
  background: #fff;
  margin-bottom: 40px;
  &__column {
    display: inline-block;
    vertical-align: top;
    margin-bottom: 10px;
    @media (--device) {
      width: 100% !important;
    }
  }
  &__title {
    display: block;
    color: $main;
    text-transform: uppercase;
    margin-bottom: 10px;
    @media (--device) {
      margin-bottom: 3px;
    }
  }
  &__value {
    color: $title;
  }
}
