.KnowTheProject {
  display: flex;
  align-items: center;
  justify-content: center;
  &_close {
    border: none;
    right: 0;
    font-size: 20px;
    position: absolute;
    z-index: 10;
    display: flex;
    width: 30px;
    height: 30px;
    align-items: center;
    justify-content: center;
    padding: 0;
  }
  &_bg {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
  &_container {
    background-color: #fff;
    max-height: calc(100vh - 60px);
    overflow: auto;
    max-width: initial !important;
    position: relative;
    border: 1px solid #000;
    background-color: #fff;
    &::-webkit-scrollbar-track {
      border-radius: 10px;
      width: 5px;
    }
    &::-webkit-scrollbar {
      width: 5px;
      background-color: #00000011;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #00000033;
    }
    &::before,
    &::after {
      content: '';
      border-bottom: 1px solid #000;
      position: absolute;
      top: 30px;
    }
    &::before {
      width: 100px;
      left: 0;
      animation: fade-left 0.25s ease;
    }
    &::after {
      width: 30px;
      right: 0;
      animation: fade-right 0.25s ease;
    }
    @media (max-width: 991px) {
      &::before,
      &::after {
        top: 15px;
      }
    }
    @media (max-width: 767px) {
      margin: 5px !important;
      max-height: calc(100vh - 10px);
    }
  }
  &_row {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 30px;
    position: relative;
    padding: 30px 0;
    text-align: left;
    align-items: center;
    &::after {
      width: 50%;
      content: '';
      border-bottom: 1px solid #ccc;
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
    }
    &:last-of-type {
      &::after {
        display: none;
      }
    }
    .image {
      animation: pop 0.25s ease;
    }
    .text {
      animation: pop 0.25s ease;
      h3 {
        color: #ec6262;
        font-size: 26px;
        font-weight: 700;
        margin-bottom: 20px;
        padding-bottom: 10px;
      }
      ul {
        padding-left: 25px;
        li {
          list-style: initial;
        }
      }
    }
    @media (min-width: 768px) {
      padding: 30px;
      .image {
        animation: fade-right 0.25s ease;
      }
      .text {
        animation: fade-left 0.25s ease;
        ul {
          padding-left: 40px;
        }
      }
      &:nth-of-type(even) {
        flex-direction: row-reverse;
        text-align: left;
        .image {
          animation: fade-left 0.25s ease;
        }
        .text {
          animation: fade-right 0.25s ease;
        }
      }
    }
  }
}

@keyframes pop {
  from {
    transform: scale(0);
  }

  to {
    transform: scale(1);
  }
}

@keyframes fade-left {
  from {
    opacity: 0;
    transform: translateX(-100%);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fade-right {
  from {
    opacity: 0;
    transform: translateX(100%);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}
