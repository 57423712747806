@define-extend login-field {
  width: 340px;
}

.modal .login-modal {
  padding: 40px;
  max-width: 480px;
  margin: 80px auto;
  padding: 46px 1px 32px 15px;
  width: 100%;
  background-color: #575252;
  position: relative;

  @media (--device) {
    margin: 80px auto;
    padding: 46px 1px 32px 5px;
  }

  &.blocked {
    background-color: #ab3f3f;
  }

  &__title {
    text-align: center;
    margin-bottom: 30px;
    font-size: 32px;
    font-weight: 700;
  }

  &__field {
    @extend login-field;

    &--checkbox {
      @extend login-field;
      margin-top: -15px;

      @media (--device) {
        width: 300px;
      }

      .FormInput {
        @extend login-field;
        max-width: 15px;
        margin-right: 5px;

        & input[type='checkbox'] {
          vertical-align: middle;
        }

        .login-modal__checkbox {
          position: relative;
          margin: 0;
        }
      }
    }

    &.blocked {
      color: #fff;
    }

    &--button {
      @extend login-field;
      max-width: 481px;

      .login-modal__button-signup {
        background-color: #f5f5f5;
        color: #575252;
      }

      @media (--device) {
        width: 300px;
      }
    }

    @media (--device) {
      width: 300px;
    }
  }

  h3 {
    color: #fff;
  }

  &__button {
    width: 100%;

    &--grey {
      background-color: #e6e6e6;
      margin-bottom: 17px !important;
      width: 100%;
      margin: 0;

      &:hover {
        box-shadow: 0px 0px 1px 1px #e6e6e6;
      }
    }
  }

  &__button-signup {
    width: 100%;
    margin: 4px 0;

    &:hover {
      box-shadow: 0px 0px 1px 1px #f5f5f5;
    }
  }

  &__forgot {
    float: right;
    color: white;
    cursor: pointer;
    font-weight: 700;
    font-size: 14px;
    margin-top: 4px;
    margin-right: 3px;

    @media (--device) {
      font-size: 12px;
    }
  }

  &__persist {
    color: white;
    font-size: 14px;
    margin: 3px 0 0 3px;
    cursor: pointer;

    @media (--device) {
      font-size: 12px;
    }
  }

  &__back {
    color: white;
    position: absolute;
    left: 7%;
    transform: scale(1, 0.9);
    top: 20px;
    font-size: 18px;
    font-weight: 700;
    cursor: pointer;
  }

  &__close {
    color: white;
    position: absolute;
    right: 7%;
    transform: scale(1, 0.9);
    top: 20px;
    font-size: 18px;
    font-weight: 700;
    cursor: pointer;
  }

  &__message--success {
    color: #fff;
    text-align: center;
  }
}
