.appearance {
  margin: 20px 0;
  &__file {
    display: inline-block;
    vertical-align: top;
  }
  &__color {
    display: inline-block;
    vertical-align: top;
    margin-left: 35px;
    @media (--phone) {
      margin-left: 0;
    }
  }
  &__message {
    font-size: 14px;
    margin-left: 6px;
  }
  &__picker {
    display: inline-block;
    vertical-align: top;
    margin-right: 20px;
  }
  &__input {
    margin-bottom: 10px;
  }
  &__label {
    color: $title;
    display: inline-block;
    font-size: 16px;
    margin-bottom: 5px;
  }
  & .file-input {
    float: none;
    display: inline-block;
    width: 232px;
  }
  &__preview {
    margin: 20px 0;
    border: 1px solid black;
    width: 232px;
    & img {
      width: auto;
      height: 65px;
      overflow: hidden;
    }
  }
  &__upload {
    cursor: pointer;
    float: none;
    & img {
      vertical-align: middle;
      margin-right: 5px;
    }
  }
}
.message {
  opacity: 1;
  margin-top: 5px;
  font-size: 12px;
  text-align: center;
  width: 234px;
}
.message--opacity {
  opacity: 0;
  margin-top: 5px;
  font-size: 12px;
  width: 234px;
  text-align: center;
  transition: opacity 3s;
}
