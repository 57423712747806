.filtertext {
  &__input {
    background: #fff;
    height: 40px;
    width: 100%;
    padding: 7px 9px;
    border: 1px $title solid;
    border-radius: 5px;
    color: $title;
    font-size: 16px;
    &::placeholder {
      color: #c8c6c6;
    }
  }
}
