.report-table {
  &__header {
    max-width: 960px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    padding: 7px 20px;
    background: #f5f5f5;
    overflow: hidden;
  }

  &__title {
    font-size: 20px;
    color: #3e5d85;
    display: inline-block;
  }

  &__export-title {
    color: $title;
    float: right;
  }

  &__export-title-label {
    margin-right: 5px;
    display: inline-block;
    vertical-align: top;
  }

  &__sync.button {
    background: #6aabd6;

    &.disabled {
      background: #999;
    }
  }

  &__voucher-status.button {
    background: #00b050;
    font-size: 12px;
    font-weight: 400;
    line-height: 28px;
    width: 100px;

    &.inactive {
      background: #ec6262;
    }
  }

  .button.orders {
    padding: 2px 6px;
  }

  .button.disabled {
    background-color: #999 !important;
    pointer-events: none !important;
  }

  .public_fixedDataTableCell_wrap1.center {
    text-align: center;
    margin: 0 auto;
  }

  .public_fixedDataTableCell_cellContent .button.center {
    display: block;
    width: fit-content;
    margin: 0 auto;
  }

  .business_lines {
    font-size: 1.24rem;
  }
}
