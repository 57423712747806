.report {
  &__table {
    margin-top: 30px;
  }
  @media (--device) {
    overflow-x: scroll;
  }
  &.users {
    .report__title {
      margin: 35px 0 10px 0;
    }
  }
  &__title {
    margin: 35px 0 0 0;
  }
  &.vouchers {
    .report-table {
      &__text-cell {
        font-size: 13px;
      }
    }
  }
}
