.special-discount {
  &__select {
    height: 62px;
    float: none;

    &:disabled {
      color: #0000006a;
    }
  }

  &__subtitle {
    display: block;
    margin-top: 5px;
    cursor: pointer;
    text-decoration: underline;
    text-align: center;

    &:hover {
      text-decoration: underline;
    }
  }

  .report__title {
    margin: 35px 0 10px 0;
  }

  .button__small {
    vertical-align: top;
  }

  .button__upload {
    float: none;
    background-color: #00a2e8;
    width: 220px;
    margin-left: 8px;
  }

  .export {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  &-form {
    .field__label {
      width: 18% !important;
    }
  }

  .filtertext__input.test-filtertext {
    width: 360px;
  }
}

.upload-errors-modal {
  .modal-dialog {
    height: 90%;
    width: 80%;
    max-width: 1090px;
    min-width: 60%;
    transform: translate(0, -50%);
    top: 1%;
  }

  .modalFooter {
    text-align: center;
  }

  .modal-content {
    height: 100%;
  }

  .modal-body {
    padding: 30px !important;
  }

  &__error-label {
    font-weight: bold;
    font-size: 12px;
  }

  &__status {
    margin-bottom: 16px;

    span {
      display: block;
    }
  }

  &__status-title {
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 4px;
  }

  .modalFooter {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .upload-file-modal__anchor-button {
    margin-bottom: 8px;
  }

  .modal-close.upload-file-modal__submit {
    width: 40% !important;
  }

  table.import-summary {
    width: 100%;
  }

  table.import-summary th {
    width: 33.33333%;
  }

  .table {
    margin-bottom: 20px;

    thead,
    tbody {
      display: block;
    }

    tbody {
      max-height: 250px;
      overflow-y: auto;
      overflow-x: hidden;
    }

    tbody td:first-child,
    thead th:first-child {
      width: 5%;
    }

    tbody td,
    thead th {
      width: 10%;
    }

    thead {
      tr:nth-child(odd) {
        background-color: #e6e6e6;
      }

      tr:nth-child(even) {
        background-color: #f5f5f5;
      }
    }

    tbody {
      tr:nth-child(even) {
        background-color: #e6e6e6;
      }

      tr:nth-child(odd) {
        background-color: #f5f5f5;
      }
    }

    ul {
      margin-top: 5px;
      list-style: disc;
      font-size: 12px;

      li {
        margin-left: 12px;
        margin-top: 4px;
      }
    }
  }
}
