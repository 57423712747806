.faqanswer {
  &__question {
    font-size: 30px;
    text-align: center;
    margin-bottom: 15px;
    &:first-letter {
      text-transform: uppercase;
    }
  }
  &__icon {
    margin-right: 8px;
    vertical-align: middle;
  }
  &__label {
    vertical-align: middle;
  }
  &__back {
    font-size: 18px;
    color: $main;
    cursor: pointer;
    &:hover {
      color: #cc1b1b;
    }
  }
  &__text {
    font-size: 18px;
    line-height: 30px;
    margin-bottom: 15px;
    &:first-letter {
      text-transform: uppercase;
    }
    ul {
      margin: 0 25px;
      li {
        list-style: outside;
      }
    }
  }
  &__button.button {
    display: block;
    width: 102px;
  }
}

