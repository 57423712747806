.multipledate {
  overflow: hidden;
  &__field {
    display: block;
    vertical-align: top;
    margin-right: 14px;
    &:last-child {
      margin-right: 0;
    }
  }
}

.multipledate__field .inputdate {
  color: $title;
  cursor: pointer;
  &__icon {
    display: inline-block;
    vertical-align: middle;
    margin-right: 8px;
  }
  &__current {
    display: inline-block;
    padding-right: 20px;
    vertical-align: middle;
    position: relative;
    font-size: 14px;
    font-weight: bold;
    &:after {
      content: '';
      width: 10px;
      height: 13px;
      display: block;
      background-image: url('../components/FilterDate/images/arrow.png');
      position: absolute;
      top: 1px;
      right: 0;
    }
  }
  &__label {
    display: inline-block;
    font-size: 14px;
    margin-right: 4px;
  }
}

.multipledate__field > div {
  display: inline-block;
}

.deleteCutDateButton {
  background: url('../components/MultipleDateInput/images/delete.png') center no-repeat transparent;
  border: none;
  cursor: pointer;
  display: inline-block;
  height: 16px;
  margin-left: 20px;
  padding: 0;
  width: 16px;
}

.addCutDateButton {
  background: #fff;
  border: 2px solid #575252;
  border-radius: 5px;
  color: #575252;
  cursor: pointer;
  display: block;
  margin: 10px auto;
  padding: 8px;
  position: relative;
  text-align: center;
  width: 80%;
}
