@define-extend voucher__insert {
  lost-column: 1/2 0 0;
  position: relative;
}

#root .voucher {
  &__infos {
    font-size: 14px;
    font-weight: bold;
    line-height: 17px;
    text-align: center;
    margin-bottom: 15px;
    color: $title;
    &__logo {
      margin-bottom: 20px;
      & img {
        width: 90px;
        height: auto;
      }
    }
  }

  &__slider {
    position: relative;
    z-index: 2;
    max-width: 542px;
    margin-left: auto;
    margin-right: auto;
    margin: 0 auto;
    overflow: hidden;
    &__content {
      width: 200%;
      &.active {
        transform: translateX(-50%);
        transition: transform 0.5s ease-out;
      }
    }
  }

  &__insert {
    @extend voucher__insert;
    @media (--phone) {
      height: auto;
    }
    &--voucher {
      @extend voucher__insert;
      margin-top: 90px;
      @media (--device) {
        margin-top: 0;
      }
    }
    &--student {
      @extend voucher__insert;
      display: none;
      margin-top: 40px;
      &.active {
        display: block;
      }
      @media (--device) {
        margin-top: 0;
      }
    }
  }

  &__info {
    display: block;
    margin: 15px 0;
    font-size: 14px;
    font-weight: 100;
    text-align: center;
    &--warn {
      color: #ec6262;
      text-align: center;
      margin-left: 68px;
      font-weight: 700;
      &--link {
        padding-left: 5px;
      }
    }
  }

  &__title {
    line-height: 40px;
    text-align: center;
    @media (--device) {
      margin: 0 0 15px 0;
      font-size: 20px;
      line-height: 24px;
    }
  }

  &__highlighted {
    color: $main;
  }

  &__input {
    width: 72%;
    height: 60px;
    padding: 0 12px;
    border: 3px $title solid;
    border-radius: 5px;
    margin-right: 1%;
    color: #575252;
    &::-webkit-input-placeholder {
      font-size: 16px;
      font-weight: 700;
      color: #575252;
    }
    @media (--device) {
      width: 100%;
    }
  }

  &__button {
    display: inline-block;
    height: 60px;
    width: 26%;
    vertical-align: top;
    color: #fff;
    background-color: $main;
    border-radius: 5px;
    border: 0;
    line-height: 60px;
    font-size: 20px;
    font-weight: 100;
    text-align: center;
    text-decoration: none;
    @media (--device) {
      display: block;
      width: 120px;
      margin: 20px auto;
    }
  }

  &__tooltip {
    background-color: #f00;
    max-width: 389px;
    color: #fff;
    position: relative;
    z-index: 1;
    font-size: 14px;
    border-radius: 5px;
    padding: 15px;
    margin-top: 10px;
    @media (--device) {
      margin-top: -80px;
    }
    &:after {
      display: block;
      position: absolute;
      content: '';
      width: 25px;
      top: -5px;
      height: 25px;
      background: #f00;
      transform: rotate(131deg);
      z-index: -2;
      left: 25px;
    }
    &__error {
      display: inline-block;
      vertical-align: middle;
      padding-left: 1px;
      width: 20px;
      line-height: 16px;
      border: 2px #fff solid;
      border-radius: 50%;
      font-size: 12px;
      font-weight: 900;
      text-align: center;
      height: 20px;
      margin-right: 15px;
    }
    &__text {
      display: inline-block;
      vertical-align: middle;
      width: 100px;
      font-weight: 700;
      width: 90%;
    }
  }

  &__expired-text {
    display: block;
    margin-top: 26px;
    font-size: 22px;
    line-height: 26px;
    margin-bottom: 26px;
  }
}

@keyframes cloud-animate {
  from {
    transform: matrix(1, 0, 0, 1, -10, -10);
  }

  to {
    transform: matrix(1, 0, 0, 1, 2, 4);
  }
}
