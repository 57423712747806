.admin-form {
  &__title {
    display: inline-block;
    font-size: 30px;
    float: left;
  }
  &__header {
    max-width: 470px;
    overflow: hidden;
    margin: 35px 0;
    &__button {
      float: right;
    }
  }
  &__checkboxtitle.field__label {
    float: none;
    display: block;
  }
  &__terms {
    margin-left: 11%;
    margin-bottom: 15px;
    @media (--phone) {
      max-width: 358px;
      margin: 0 auto 15px auto;
    }
    .FormInput {
      display: inline-block;
    }
  }
  &__succeed {
    color: $succeed;
    font-weight: bold;
    display: inline-block;
    margin-left: 10px;
  }
  &__form {
    &__label {
      width: 10%;
      margin-right: 10px;
      text-align: right;
      @media (--phone) {
        width: auto;
      }
    }
    .field .FormInput {
      max-width: 358px;
    }
  }
  &__buttons {
    margin-left: 11%;
    @media (--phone) {
      max-width: 358px;
      margin: 0 auto;
    }
  }
  &__button {
    width: 157px;
  }
  .field {
    padding: 0;
  }

  &__checkbox {
    margin-left: 11%;
    margin-bottom: 15px;
    @media (--phone) {
      max-width: 358px;
      margin: 0 auto 15px auto;
    }
    .field {
      .FormInput {
        width: auto;
        margin-right: 10px;
      }
    }
  }
  &__select {
    margin-left: 11%;
    margin-bottom: 15px;
    color: #000;
    @media (--phone) {
      max-width: 358px;
      margin: 0 auto 15px auto;
    }
    .field {
      .FormInput {
        width: auto;
        margin-right: 10px;
      }
    }
  }

  &__checkboxlabel {
    font-size: 16px;
    display: inline-block;
    vertical-align: top;
    color: $title;
  }
  &__checkboxtitle {
    margin: 20px 0;
  }
  &__division {
    display: inline-block;
    width: 100%;
    max-width: 300px;
    margin-left: 10%;
  }
}
