.pagination {
  font-size: 32px;
  letter-spacing: 2px;
  margin: 50px 50px;

  ul {
    text-align: center;
  }

  ul > li {
    display: inline-block;
    cursor: pointer;
  }
}
