.scroll-area {
  height: 100%;
  overflow: scroll;
  position: relative;
}

@define-extend message-card {
  padding: 5px 0;
  overflow: hidden;
  margin: 8px 0;
  background-color: $checkoutDiscount;
  color: $title;
  border-radius: 5px;
  font-size: 18px;
}

.special-discount-header {
  text-align: center;
  padding: 0.8rem;
  font-size: 17px;
  font-weight: bold;
  background-color: #ec6261;
  color: #fff;
  border-radius: 5px;
  margin-bottom: 1px;
  border: 1px solid #c7c7c7;
}

.student-checkout {
  @media (--phone) {
    z-index: 2;
    width: 100%;
    left: 0;
    bottom: 0;
    transform: translate3d(0, 0, 0);

    &.scroll {
      overflow-y: scroll;
      padding-top: 100px;
      height: 100%;
    }
  }

  &__container {
    background: $checkoutStudent;
    border-radius: 5px;
    position: relative;
    z-index: 2;
    overflow: hidden;
    transition: 0.4s min-height ease-in;

    @media (--phone) {
      border-radius: 0;
      background: transparent;
    }
  }

  &__mobile-view {
    @media (--desktop) {
      margin-bottom: 110px;
    }

    &.toggle-enter {
      animation: openCheckout 0.4s linear;
    }

    &.toggle-leave {
      animation: closeCheckout 400ms linear;
      animation-delay: -80ms;
    }
  }

  &__editing {
    width: 25%;
    float: left;
    padding-right: 11px;
    margin-left: 2%;
    border-right: 2px #fff solid;
    display: none;

    @media (--phone) {
      display: block;
    }

    &__count {
      display: block;
      width: 22px;
      height: 22px;
      line-height: 18px;
      margin: 0 auto;
      font-size: 14px;
      border-radius: 50%;
      border: 2px #fff solid;
      text-align: center;
    }

    &__name {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-block;
      text-align: center;
      margin-top: 5px;
    }

    &__arrow {
      display: none;
      border: solid #fff;
      border-width: 0 3px 3px 0;
      padding: 5px;
      transform: rotate(223deg);
      float: right;
      margin-top: 22px;
      margin-right: 15px;
      transition: transform 0.2s linear;

      &.close {
        transform: rotate(405deg);
        margin-top: 18px;
      }

      @media (--phone) {
        display: block;
      }
    }
  }

  &__list {
    padding: 2px;

    @media (--phone) {
      background-color: #efefef;
    }
  }

  &__item {
    padding: 20px 23px;
    margin-bottom: 3px;
    overflow: hidden;
    color: $title;
    transition: 1s box-shadow;

    &--selected {
      padding: 20px 23px;
      overflow: hidden;
      color: $title;
      margin-bottom: 3px;
      box-shadow: 1px 1px 1px 2px #c7c7c7;
    }
  }

  &__position {
    float: left;
    width: 36px;
    line-height: 36px;
    background: $title;
    color: #fff;
    border-radius: 50%;
    text-align: center;
    margin: 15px 15px 0 0;

    @media (--phone) {
      margin: 0 15px 0 0;
      width: 19px;
      line-height: 19px;
      font-size: 14px;
    }
  }

  &__info {
    float: left;
    width: 43%;
    vertical-align: middle;

    @media (--tablet) {
      width: 33%;
    }

    span {
      display: block;
      /*margin-bottom: 5px;*/
    }

    &__name {
      font-size: 20px;
      font-weight: bold;

      @media (--tablet) {
        font-size: 14px;
      }
    }

    &__voucher {
      margin: 5px 0;
    }

    &__voucher,
    &__amount {
      font-size: 14px;
      font-weight: 400;
    }
  }

  &__icons {
    width: 33%;
    float: right;
    text-align: right;

    &__delete,
    &__edit {
      display: inline-block;
      margin-left: 10px;
      text-align: right;
      cursor: pointer;
    }

    &__price {
      display: block;
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 2px;

      @media (--device) {
        font-size: 14px;
      }
    }

    &__discount {
      font-size: 14px;
      font-weight: 400;
    }
  }

  &__new {
    background-color: #dbdbdb;
    padding: 20px 0;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    color: $title;
    cursor: pointer;
    border: 3px #95989a dashed;

    @media (--device) {
      padding: 13px 0;
    }

    img {
      display: inline-block;
    }
  }

  &__total {
    background-color: $main;
    width: 100%;
    padding: 23px 0;
    color: #fff;
    overflow: hidden;
    margin: 0;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;

    @media (--phone) {
      padding: 19px 0 15px 0;
      background-color: $main;
      position: relative;
      bottom: 0;
      left: 0;
    }

    &__label {
      font-size: 20px;
      font-weight: 400;
      margin: 15px 5%;
      float: left;

      @media (--tablet) {
        margin: 15px 2%;
      }

      @media (--phone) {
        font-size: 18px;
        margin: 15px 2%;
        margin-right: 7px;
      }
    }

    &__price {
      float: right;
      text-align: right;
      margin-right: 17px;

      @media (--phone) {
        margin-top: 5px;
      }
    }

    &__from {
      display: block;
      font-size: 16px;

      @media (--phone) {
        font-size: 14px;
      }
    }

    &__to {
      font-size: 24px;

      @media (--phone) {
        font-size: 17px;
      }
    }
  }

  &__savingup {
    @extend message-card;
    background-color: $checkoutDiscount;

    @media (--phone) {
      width: 100%;
      position: absolute;
      bottom: 84px;
      left: 0;
      z-index: 1;
      padding: 2px;
      margin: 0;
      font-size: 14px;
      border-radius: 0;
      transition: transform 0.5s ease-out;
      transform: translate(0, 0);

      &.disabled {
        transform: translate(100%, 0);
      }
    }

    &__label {
      width: 75%;
      float: left;
      margin: 12px;

      @media (--tablet) {
        width: 50%;
      }

      @media (--phone) {
        margin: 12px 8px;
      }
    }

    &__price {
      font-weight: 900;
    }

    img {
      float: right;
      margin-right: 5%;

      @media (--tablet) {
        margin-top: 20px;
      }
    }
  }

  &__shipping {
    @extend message-card;
    background-color: #f5f5f5;

    @media (--phone) {
      width: 100%;
      position: absolute;
      bottom: 130px;
      left: 0;
      z-index: 1;
      padding: 2px;
      margin: 0;
      font-size: 14px;
      border-radius: 0;
      transition: transform 0.5s ease-out;
      transform: translate(0, 0);

      &.disabled {
        transform: translate(100%, 0);
      }
    }

    &__label {
      width: 75%;
      float: left;
      margin: 12px;

      @media (--tablet) {
        width: 50%;
      }

      @media (--phone) {
        margin: 12px 8px;
      }
    }

    img {
      float: right;
      margin-top: 4px;
      margin-right: 4%;

      @media (--tablet) {
        margin-top: 20px;
      }
    }
  }
}

.student-checkout__total__from.item {
  display: block;
  font-size: 14px;
  text-decoration: line-through;
}

.student-checkout__total__to.item {
  font-size: 17px;
  margin-right: 0;
  font-weight: bold;
}

@key frames openCheckout {
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0%);
  }
}

@keyframes closeCheckout {
  from {
    transform: translateY(0%);
  }

  to {
    transform: translateY(100%);
  }
}

.tooltip {
  width: 200px;
  background-color: #eccc62;
  color: #575252;
  text-align: center;
  position: absolute;
  z-index: 1;
  font-size: 16px;
  border-radius: 5px;
  right: 50px;
  padding: 15px;
  margin-top: 10px;

  @media (--device) {
    bottom: 95px;
  }

  &:after {
    display: block;
    position: absolute;
    content: '';
    width: 25px;
    height: 25px;
    background: #eccc62;
    transform: rotate(131deg);
    z-index: -1;
    bottom: 43px;
    right: 25px;

    @media (--device) {
      bottom: -5px;
    }
  }

  &__title {
    font-weight: 700;
  }

  &__body-content {
    font-weight: 400;
  }
}

.single {
  margin-top: 12px;

  @media (--device) {
    margin-top: 10px;
  }
}
