.card-request-detail {
  display: block;
  position: relative;
  margin-bottom: 30px;
  background-color: #ffffff;
  max-width: 900px;
  box-shadow: none;
  transition: box-shadow 0.1s linear;

  @media (--tablet) {
    margin: 20px auto;
  }

  @media (--phone) {
    margin: 20px auto;
  }

  &:hover {
    box-shadow: 0px 2px 16px #888888;
    transition: box-shadow 0.1s linear;
  }

  &__wrapper {
    display: flex;
    max-width: 591px;
  }

  &__footer {
    display: inline-block;
    max-width: 100%;
    width: 100%;
  }

  &__header {
    display: block;
    text-transform: uppercase;
    color: #ec6262;
    font-size: 16px;
    font-weight: 700;
    margin-top: 20px;
    margin-bottom: 20px;

    /* @media (--phone) {
      font-size: 12px;
    } */
  }

  &__value {
    display: block;
    font-size: 16px;
    color: #575252;

    &.dashed {
      text-decoration: line-through;
      font-style: italic;
      text-decoration-color: #000;
      color: #00000052;
    }

    /* @media (--phone) {
      font-size: 16px;
      margin-top: 5px;
    } */
  }

  &__icon {
    display: inline-block;
    margin-right: 11px;
    vertical-align: middle;
    width: 44px;
    @media (--phone) {
      margin-top: 5px;
    }
  }

  &__column {
    display: inline-block;
    vertical-align: top;
    padding: 1.2em;
    @media (--phone) {
      display: block;
    }
    &.paymente_detail {
      flex: 1;
    }
  }

  &__footer {
    .card-request-detail__column {
      width: 42%;
      box-sizing: border-box;
    }

    .card-request-detail__header {
      margin-top: 0px;
    }

    .card-request-detail__value {
      margin-top: 5px;
    }
  }

  &__status-container {
    height: 100%;
    width: 14%;
    position: absolute;
    top: 0;
    right: 0;
    display: flex;

    @media (--phone) {
      height: 90px;
      width: 100%;
      position: initial;
      text-align: center;
    }
  }

  &__desc {
    display: block;
    font-size: 14px;
    margin-top: 5px;
    color: #575252;
  }

  &__billetbutton {
    margin-top: 10px;
  }
  &__payment_type {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
  }
  &__paymente_detail {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    margin: 20px 0;
    .card-request-detail__header {
      margin: 0;
    }
    &._total {
      padding-top: 20px;
      border-top: 1px solid #333;
      justify-content: flex-end;
    }
  }
}

.row-xs{
  @media (--phone) {
    flex-direction: row;
    justify-content: space-around;
  }
}
.column-xs{
  @media (--phone) {
    flex-direction: column;
  }
}