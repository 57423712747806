.ticket {
  margin: 30px;
  &__iframe {
    width: 80%;
    max-width: 960px;
    height: 100vh;
    border: none;
  }
  &__title {
    margin-bottom: 20px;
  }
}
