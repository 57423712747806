.container-menu {
  overflow: hidden;

  &__toggle {
    display: block;
    width: 24px;
    height: 16px;
    position: relative;
    z-index: 2;
    margin: 32px 0;
    float: right;
    transform: rotate(0deg);
    transition: 0.5s ease-in-out;
    cursor: pointer;
    @media (--desktop) {
      display: none;
    }
    span {
      display: block;
      position: absolute;
      height: 3px;
      width: 100%;
      background: #fff;
      border-radius: 9px;
      opacity: 1;
      left: 0;
      transform: rotate(0deg);
      transition: 0.25s ease-in-out;
      &:nth-child(1) {
        top: 0;
      }
      &:nth-child(2) {
        top: 8px;
      }
      &:nth-child(3) {
        top: 16px;
      }
    }
    &.active {
      top: 8px;
      span {
        &:nth-child(1) {
          top: 0;
          transform: rotate(135deg);
        }
        &:nth-child(2) {
          opacity: 0;
          left: -60px;
        }
        &:nth-child(3) {
          top: 0;
          transform: rotate(-135deg);
        }
      }
    }
  }
  &__content {
    @media (--device) {
      width: 100%;
      padding: 0 20px;
      position: fixed;
      right: 0;
      top: 80px;
      z-index: 4;
      background: $title;
      transform: translateX(100%);

      &.open {
        height: 100%;
        transform: translateX(0);
        transition: transform 0.4s ease-in;
      }

      &.close {
        height: 100%;
        transform: translateX(100%);
        transition: transform 0.4s ease-out;
      }

      &.force-close {
        display: none;
      }
    }
  }
}
