.header_buttons {
  display: flex;
}

.buttons_action {
  display: flex;
}

/* &__header media query for screen < 800 */
@media screen and (max-width: 995px) {
  .header_buttons {
    display: block;
  }
}

.order-detail {
  display: block;
  margin: 0 auto;

  @media (--tablet) {
    width: 100%;
  }

  &__header {
    margin: 30px;
  }

  &__title {
    display: inline-block;
    vertical-align: top;
    margin-right: 25px;
    @media (--tablet) {
      margin: 0;
      margin-right: 10px;
    }
    @media (--phone) {
      margin: 5px;
    }
  }

  &__cancelbutton.button {
    color: #575252;
    margin-left: 10px;
    margin-right: 10px;
  }

  &__approvebutton.button {
    color: #575252;
    margin-left: 10px;
  }

  &__wrapper {
    background-color: #f5f5f5;
    margin-top: 2px;
    border-bottom-right-radius: 9px;
    border-bottom-left-radius: 9px;
    overflow: hidden;
  }
  &__table-title {
    padding: 10px 0 1px 20px;
    font-size: 16px;
    color: #ec6a6a;
    font-weight: 700;
  }

  &__total {
    background: #ec6262;
    width: 100%;
    height: 55px;
  }

  &__text {
    color: #fff;
    float: left;
    margin: 10px;
  }

  &__value {
    color: #fff;
    float: right;
    font-size: 24px;
    font-weight: 700;
    margin: 10px;
  }

  &__license_activation_link {
    padding: 10px 25px;
    p {
      font-size: 14px;
      margin-bottom: 0;
    }
  }

  .alert-card-student-orders{
    padding: 10px;
    background: #ffe3af9e;
    border: solid 1px orange;
  }

}