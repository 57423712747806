.file-input {
  float: right;
  max-width: 250px;

  & label {
    display: block;
    float: none;
    margin: 0 auto;
  }

  & span {
    font-size: 11px;
  }
}
