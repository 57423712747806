.filters {
  display: flex;
  flex-wrap: wrap;
  max-width: 960px;
  margin-top: 30px;
  @media (--device) {
    justify-content: center;
  }
  /* text-align: right; */
  &__item {
    display: flex;
    vertical-align: middle;
    min-width: 32%;
    margin-right: 2%;
    margin-bottom: 11px;
    @media (--device) {
      min-width: 100%;
      margin-right: 0;
    }
    &:nth-child(3n) {
      @media (--desktop) {
        margin-right: 0;
      }
    }

    & > div {
      width: 100%;
    }
  }
}
