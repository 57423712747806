.btn_state {
  display: inline-block;
  cursor: pointer;
  font-size: 18px;
  font-weight: 100;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  border: 0;
  padding: 0 15px;
  transition: 0.3s linear;

  &__active {
    background-color: #268a30 !important;
  }

  &__inactive {
    background-color: #ec6262 !important;
  }

  &:disabled {
    background-color: #999 !important;
  }
}

.field__freight_amount {
  font-size: 13px !important;
  line-height: 2em !important;
  width: 75px !important;
  padding: 0px 10px !important;

  &:disabled {
    background-color: transparent !important;
    border-color: transparent !important;
  }
}

.freight_amount_container {
  display: inline-block;
  margin-right: 5px;
}

.report-table__header {
  max-width: 1005px !important;
}
