@define-extend schoolcard {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  max-width: 423px;
  min-height: 184px;
  background-color: #fff;
  padding: 25px 20px;
  margin-right: 20px;
  margin-bottom: 20px;
}

@define-extend schoolcard__title {
  display: block;
  color: $main;
  text-transform: uppercase;
  margin-bottom: 14px;
}

.schooldetail {
  max-width: 886px;

  &__header {
    width: 100%;
    max-width: 867px;
    overflow: hidden;
  }

  &__title {
    display: inline-block;
    vertical-align: top;
    text-transform: capitalize;
    margin-bottom: 20px;
  }

  &__upload {
    float: right;

    @media (--phone) {
      float: none;
      margin-bottom: 20px;
    }
  }

  &__report {
    display: inline-block;
    max-width: 960px;
  }

  &__message {
    float: right;
    opacity: 1;
    font-size: 12px;
    margin: 7px 8px;
  }

  &__message--opacity {
    float: right;
    opacity: 0;
    font-size: 12px;
    margin: 7px 8px;
    transition: opacity 3s;
  }

  & .file-input {
    @media (--phone) {
      float: none;
    }
  }
}

.overview-pack {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  max-width: 423px;
  min-height: 184px;
  margin-right: 20px;
}

.schooloverview {
  @extend schoolcard;

  &__title {
    @extend schoolcard__title;
  }

  &__columns {
    display: flex;
    justify-content: space-between;
  }

  &__value {
    display: block;
    font-size: 30px;
    font-weight: 900;
    color: $main;
    text-align: center;
  }

  &__label {
    display: block;
    font-size: 12px;
    font-weight: 700;
    text-align: center;
  }

  &__expected_start {
    margin-top: 2rem;
  }

  .contract-packs {
    li {
      font-weight: bold;

      > span {
        display: inline-block;
        width: 40%;
      }

      label {
        margin: 0 0 0 5px;
        position: relative;
        top: 4px;

        span {
          margin: 2px 5px;
          font-size: 1.5rem;
        }
      }
    }
  }
}

.schoolcard {
  @extend schoolcard;

  .schoolcard__title__buttons {
    display: flex;
    justify-content: space-between;

    @media (--phone) {
      display: block;
    }
  }

  .button {
    width: 19rem;
    margin-bottom: 1rem;
    @media (--phone) {
      margin-bottom: 0px;
    }
  }

  &__title {
    @extend schoolcard__title;

    &--inline {
      @extend schoolcard__title;
      display: inline;
      vertical-align: middle;
    }

    & button {
      float: right;

      @media (--phone) {
        float: none;
        margin: 10px 0;
      }
    }
  }

  &__content {
    height: 41px;
    padding: 10px 0;

    @media (--phone) {
      height: 35px;
    }
  }

  &__value {
    display: block;
  }

  &__initial-date {
    @media (--phone) {
      display: block !important;
    }
  }

  &__end-date {
    @media (--phone) {
      display: block !important;
    }
  }

  &__download {
    vertical-align: middle;
    cursor: pointer;
    margin-top: 10px;

    & img {
      vertical-align: middle;
      margin-left: 10px;
    }

    @media (--phone) {
      float: none;
      margin-top: 10px;
    }
  }

  &__save {
    height: 30px;
    width: 66px;
    font-size: 12px !important;
    float: right;
    padding: 0 13px;
  }

  &__footer {
    & span {
      display: inline;
      font-size: 12px;
    }

    & input {
      border-top: none;
      border-left: none;
      border-right: none;
    }

    & .inputdate {
      margin-top: 3px;
    }

    & .inputdate__current {
      font-size: 12px;
      margin-right: 2px;
    }
  }

  &__checkbox_area {
    display: flex;
    align-items: center;
  }

  &__checkbox {
    width: 19px;
    height: 19px;
    margin-right: 5px !important;
    appearance: none;
    background-color: #fff;
    border: 1px solid #000000 !important;
    border-radius: 3px;
    text-align: left;

    &:checked {
      color: #ffffff;
      border: 0;
      background: url('../components/SelectProducts/images/mark.png') no-repeat center;
      background-size: cover;
    }
  }

  & .delivery_type_educamos {
    .delivery_type_educamos_row {
      margin-bottom: 10px;
      label {
        width: 40px;
      }
      select {
        flex: 1;
        padding: 7px 10px;
        min-width: 50%;
        background: #fff;
        border: 2px solid #575252;
        border-radius: 5px;
      }
    }
  }
}
.school-discount-card {
  width: 40%;
  display: inline-block;
  vertical-align: middle;
  margin-right: 20%;
  margin-bottom: 20px;

  &:nth-of-type(2n) {
    margin-right: 0;
  }

  &__column {
    width: 40%;
    display: inline-block;
    vertical-align: middle;
    margin-right: 15px;

    @media (--phone) {
      width: 100%;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &__title {
    font-weight: 900;
    color: #575252;
    font-size: 16px;
    margin-bottom: 4px;
  }
}

.actions-area {
  display: flex;
  justify-content: space-around;
  margin: 20px;
  font-size: 15px;
  color: #ffffff;
}

.btn-accept {
  width: 200px;
  background: #00b545;
  text-align: center;
  padding: 10px;
  border: none;
  border-radius: 3px;
}

.btn-decline {
  width: 200px;
  background: #03b6fc;
  text-align: center;
  padding: 10px;
  border: none;
  border-radius: 3px;
}

.schooldetail_wrapper {
  display: flex;
  flex-wrap: wrap;
  .schooldetail_wrapper_col {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: space-between;
    &_fullwidth {
      flex: inherit;
      width: 100%;
      .schoolcard {
        max-width: 866px;
      }
    }
    @media (max-width: 1200px) {
      width: 100%;
      flex: inherit;
    }
  }
}

.automatic_inactivation,
.digital_solution {
  &_header {
    margin-bottom: 10px;
    display: flex;
    p {
      font-size: 14px;
      line-height: 1.42857143;
      color: #333;
    }
  }
  &_wrapper {
    display: flex;
    flex-wrap: wrap;
  }
  &_col {
    flex: 2;
    &.buttons {
      flex: 1;
      max-width: 250px;
      padding: 20px 20px 0;
      @media (max-width: 767px) {
        max-width: 100%;
      }
      @media (max-width: 470px) {
        padding: 20px 0 0;
      }
    }
    @media (max-width: 767px) {
      width: 100%;
      max-width: 100%;
      flex: inherit;
    }
  }
  &_btn {
    label {
      width: 100%;
      font-weight: 700;
      background-color: #f9cfcf;
      color: #fff;
      border: 2px solid #d75959;
      padding: 10px;
      margin: 5px 0;
      border-radius: 5px;
      text-align: center;
      cursor: pointer;
      transition: all ease 0.3s;
      &:hover {
        background-color: #d75959;
      }
    }
    input[type='radio'] {
      &:checked {
        & + label {
          background-color: #d75959;
        }
      }
      &:disabled {
        cursor: default;
        & + label {
          cursor: default;
          border: 2px solid #ffe8e8;
          background-color: #ffe8e8;
          &:hover {
            cursor: default;
            background-color: #ffe8e8;
          }
        }
      }
    }
  }
  &_disable_digital_licenses {
    padding: 20px;
    .schoolcard__checkbox_area span {
      margin-top: 3px;
    }
  }
  &_footer {
    display: flex;
    justify-content: flex-end;
    button {
      font-weight: 700;
      transition: all ease 0.3s;
    }
  }
  &_datepicker {
    width: 100%;
  }
}
.digital_solution_description {
  animation: fade__down 0.3s;
  font-size: 14px;
  line-height: 1.42857143;
  color: #333;
  padding: 25px 0px;
  @media (max-width: 767px) {
    padding: 20px;
  }
  @media (max-width: 470px) {
    padding: 20px 0 0;
  }
  p {
    font-size: 14px;
    line-height: 1.42857143;
    color: #333;
  }
  .digital_solution_hybrid {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    .digital_solution_hybrid_col {
      width: 50%;
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      margin-bottom: 20px;
      padding: 10px;
      .digital_solution_hybrid_select {
        display: flex;
        margin-bottom: 10px;
        flex: 1;
        label {
          flex: 1;
          text-align: right;
          padding-right: 10px;
          font-size: 13px;
          max-width: 120px;
          @media (max-width: 470px) {
            flex: inherit;
            width: 100%;
          }
        }
        select {
          max-width: 163px;
          option {
            color: #333;
          }
          @media (max-width: 470px) {
            width: 100%;
          }
        }
        @media (max-width: 1200px) {
          width: 100%;
          flex: inherit;
        }
      }
      @media (max-width: 1200px) {
        width: 100%;
      }
    }
  }
}
.school_detail_modal {
  display: flex;
  padding: 30px 0;
  align-items: center;
  justify-content: center;
  animation: fade__down 0.3s;
  &_content {
    background-color: #fff;
    max-width: 100%;
    width: 500px;
    -webkit-box-shadow: 0 5px 15px rgb(0 0 0 / 50%);
    box-shadow: 0 5px 15px rgb(0 0 0 / 50%);
    border-radius: 6px;
    .school_detail_modal_header {
      border-bottom: 1px solid #ccc;
      margin-bottom: 20px;
      padding: 15px 15px 0 15px;
      text-align: center;
      .icon {
        background-size: cover;
        display: block;
        height: 28px;
        margin: 0 auto;
        position: relative;
        width: 34px;
      }
      .school_detail_modal_title {
        color: #ec6262;
        font-size: 20px;
        margin: 20px 0;
      }
    }
    .school_detail_modal_main {
      padding: 15px;
    }
    .school_detail_modal_footer {
      margin-top: 20px;
      position: relative;
      .school_detail_modal_close {
        background: #ec6262;
        border: none;
        color: #fff;
        display: block;
        height: 48px;
        border-radius: 0 0 6px 6px;
        position: relative;
        text-align: center;
        width: 100%;
      }
    }
  }
}

@keyframes fade__down {
  from {
    opacity: 0;
    transform: translateY(-30px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

