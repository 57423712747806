.register {
  max-width: 556px;
  margin: 0 auto;

  &__header {
    float: right;

    @media (--device) {
      float: none;
    }
  }

  &__title {
    margin: 0;
    margin-bottom: 7px;
  }

  &__subtitle {
    margin-bottom: 30px;
  }

  &__buttons {
    clear: both;
    text-align: right;

    @media (--phone) {
      text-align: center;
      margin-bottom: 60px;
    }
  }

  &__button {
    width: 144px;
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }

    @media (--phone) {
      width: 134px;
    }
  }

  &__terms {
    overflow: hidden;
    width: 100%;
    max-width: 556;
    float: right;
    text-align: left;
    margin-bottom: 20px;

    &:after {
      content: '';
      display: block;
      clear: both;
    }

    & > label {
      display: block;
      margin-bottom: 8px;
    }

    a {
      color: #337ab7;
    }

    .FormInput {
      display: inline-block;
      vertical-align: top;
    }

    &__label {
      display: inline-block;
      vertical-align: top;
      color: $title;
      font-size: 14px;
      margin: 2px 0 0 5px;

      @media (--phone) {
        font-size: 12px;
      }
    }
  }

  &__form {
    clear: both;

    @media (--device) {
      padding: 0 20px;
    }

    .FormInput {
      max-width: 358px;
    }

    .field__radio__label {
      margin-bottom: 5px;
    }

    &__label {
      width: 30%;
      margin-right: 20px;
      text-align: right;

      @media (--device) {
        width: 100%;
        text-align: left;
      }
    }

    .field.disabled {
      opacity: 0.3;
      pointer-events: none;
    }

    .field__address_search {
      overflow: visible;
    }

    .field__address_search .fa {
      font-size: 3.8rem;
      margin-top: 1rem;
    }

    .field__address_search.incomplete .fa {
      color: #ec6262;
    }

    .field__address_search.found .fa {
      color: #3ee14e;
    }

    .field__address_search .found_address {
      width: 100%;
      display: inline-block;
      text-align: left;
      max-width: 358px;
      background-color: #3ee14e3d;
      padding: 1.3rem;
      border-radius: 5px;
      line-height: 2rem;
      position: relative;
      font-size: 1.55rem;
    }

    .modify_address {
      position: absolute;
      right: -3rem;
      background-color: #268a30;
      top: 0;
      height: 100%;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      color: #000 !important;
      width: 3rem;
      overflow: hidden;
    }

    .modify_address .fa {
      font-size: 1.7rem;
      position: absolute;
      left: 0.8rem;
      top: 2.5rem;
    }

    .field__address_search .FormInput {
      overflow: visible;
      position: relative;
    }

    .field__address_search .FormInput input {
      padding-left: 8rem;
    }

    .field__address_search .cep-search {
      position: relative;
    }

    .field.disabled .FormError {
      display: none !important;
    }

    .field__address_search .cep-search .FormInput + .FormError {
      margin-left: 33.5%;
    }

    .field__address_search .cep-search .FormInput.-hasError + .FormError {
      display: none !important;
    }

    .field__address_search .cep-search .open-correios {
      display: block;
      text-align: right;
    }

    .field__address_search .cep-search .open-correios:hover {
      text-decoration: underline;
    }

    .field__address_search .cep-search > span {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      top: 2rem;
      position: absolute;
      padding: 2rem;
      background-color: #ccc;
      z-index: 2;
      width: auto;
      margin: 0;
    }

    .field__address_search.incomplete .cep-search > span {
      background-color: #ec6262;
      color: #fff;
    }

    .field__address_search .loading {
      position: absolute;
      top: 1.5rem;
      right: -2.3rem;
      font-size: 0.8rem;
    }

    .field__address_search .loading .fa {
      font-size: 1.6rem;
      color: #666;
    }

    .field__receive_surveys,
    .field__receive_offers {
      label {
        @media (--phone) {
          display: inline-flex;
        }
      }
    }

    @media (--device) {
      .field .FormInput {
        max-width: 100%;
      }
    }
  }

  &__required {
    color: $main;
    padding-left: 2px;
  }

  &__cep-link {
    float: right;
    @media (--phone) {
      top: 0px;
      left: 0px;
    }
  }
  .address_delivery_fields,
  .address_fields {
    background-color: #fdefef;
    padding: 0 10px 10px;
    .field__divider {
      background-color: #ec6262;
      color: #fff;
      text-align: center;
      padding: 3px 10px;
      margin: 0 -10px 20px;
    }
    .warning {
      margin: 10px;
      font-size: 14px;
      text-align: left;
      font-weight: 600;
      span {
        color: #ec6262;
      }
      @media (--phone) {
        font-size: 12px;
      }
    }
    .field {
      display: flex;
      .zipcodefield {
        flex: 1;
      }
      @media (max-width: 992px) {
        flex-wrap: wrap;
      }
    }
    .same_address_label {
      display: flex;
      align-items: center;
      margin: 10px;
      margin-bottom: 20px;
      .field__label_same_address_ {
        margin-left: 10px;
        text-align: left;
      }
    }
    .FormError {
      margin-left: 0;
      padding-left: 0;
    }
    @media (max-width: 767px) {
      max-width: 338px;
      margin: auto;
      .field__divider {
        max-width: initial;
      }
    }
  }

  .address_delivery_fields {
    background-color: #deebf7;
    margin-bottom: 20px;
    .field__divider {
      background-color: #2e5496;
    }
  }
}

.field_ie_free {
  margin-left: 33.5% !important;
  text-align: left;
}

.field_ie_free label .FormInput {
  display: inline;
}

.field_ie_free label span {
  margin-left: 1rem;
  position: relative;
  top: -0.5rem;
}

.invalid-zip {
  margin-left: 30%;
  padding-left: 25px;
}
