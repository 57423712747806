.customlegend {
  text-align: center;
  &__item {
    display: inline-block;
    vertical-align: top;
    width: 50%;
    float: left;
    &:last-child {
      float: right;
      width: 43%;
      text-align: left;
      margin-left: 20px;
    }
  }
  &__circle {
    display: inline-block;
    vertical-align: middle;
    background-color: black;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    margin-right: 12px;
  }
  &__label {
    display: inline-block;
    vertical-align: middle;
  }
}
