.school-list {
  scrollbar-width: auto;
  scrollbar-color: #ec6262 #ffffff;
  position: absolute;
  padding: .5rem 1rem;
  border-end-end-radius: .5rem;
  border-end-start-radius: .5rem;
  height: max-content;
  max-height: 120px;
  overflow-y: auto;
  width: 100%;
  max-width: 460px;
  background-color: #ffffff;
  z-index: 9999999999;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;

  &__item {
    width: 100%;
    text-align: start;
    background: none;
    border: none;
  }
  &__item:hover {
    cursor: pointer;
    background-color: rgba(215, 213, 213, 0.428);
  }
}