.step {
  width: 100%;
  height: auto;
  position: relative;

  &__container {
    padding: 25px;
    lost-utility: clearfix;
    @media (--phone) {
      padding: 25px 0;
    }
  }

  &__transition {
    lost-column: 2/3 0 0;
    @media (--phone) {
      padding-bottom: 120px;
      lost-column: 1/1;
    }
  }

  &__checkout {
    lost-column: 1/3 0 0;
    @media (--phone) {
      lost-column: 1/1;
    }
  }
}
