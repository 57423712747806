@define-extend header__list {
  display: inline-block;
  list-style: none;
  margin: 0;
  padding: 29px 0;
  vertical-align: top;
  margin-right: 30px;
}

@define-extend header__item {
  display: inline-block;
  margin-right: 30px;
}

@define-extend header__link {
  display: inline-block;
  font-size: 16px;
  text-transform: capitalize;
  color: #fff;
}

.header {
  background: $main;
  padding: 0 15px;
  height: 80px;
  overflow: hidden;

  &__grid {
    lost-utility: clearfix;
    width: 100%;
    max-width: 1366px;
    margin: 0 auto;
  }

  &__column {
    lost-column: 1/2;

    &:nth-child(2) {
      text-align: right;
    }

    @media (--device) {
      width: 70px;
    }
  }

  &__logo {
    display: inline-block;
    height: 53px;
    width: 160px;
    overflow: hidden;
    margin: 11px 0;

    &.logged {
      width: 220px;
      height: 80px;
      margin: 0;
      padding-top: 13px;
      margin-left: -14px;
      text-align: center;
    }

    img {
      vertical-align: middle;
      max-width: 100%;
      height: 52px;
    }
  }

  &__item {
    float: left;
    margin-top: 30px;
    cursor: pointer;

    @media (--desktop) {
      display: none !important;
    }

    &.fa-user {
      font-size: 25px;
      color: #fff;
    }
  }
}

.main-menu {
  &__list {
    @extend header__list;

    @media (--device) {
      display: block;
      margin: 0;
      text-align: center;
    }

    &:last-child {
      margin-right: 0;
    }

    &--icon {
      @extend header__list;
      padding: 20px 0;

      &:last-child {
        margin-right: 0;
      }

      @media (--device) {
        display: none;
      }
    }
  }

  &__item {
    @extend header__item;

    a {
      display: block;
    }

    @media (--device) {
      margin: 0 0 45px 0;
      display: block;
    }

    &--icon {
      @extend header__item;
      text-align: center;

      a {
        color: #fff;
      }

      i {
        font-size: 25px;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &__icon {
    display: inline-block;
    vertical-align: bottom;
  }

  &__link {
    @extend header__link;
    text-transform: uppercase;

    @media (--device) {
      font-size: 24px;
      text-transform: capitalize;
    }

    &--icon {
      @extend header__link;
      width: 100%;
      font-size: 12px;
    }

    &:hover {
      color: #fff;
    }
  }
}

.admin-menu {
  color: #575252;

  &__user {
    max-width: 180px;
    margin: 30px 35px;
  }

  hr {
    width: 100%;
    margin: 22px 0;
  }

  a {
    color: #575252;

    &:hover {
      color: #f5a8a8;
    }
  }

  &__title {
    display: block;
    text-align: left;
    font-size: 18px;
    font-weight: 700;
  }

  &__edit {
    display: block;
    text-align: left;
    font-size: 12px;
  }

  &__list {
    margin: 20px 35px;
    text-align: left;
  }

  &__item {
    margin-top: 10px;
    cursor: pointer;

    &--subitem {
      margin-left: 24px;

      .admin-menu__item-text {
        font-size: 14px;
      }

      &:hover {
        color: #f5a8a8;
        /*font-weight: 700;*/
      }
    }
  }

  &__icon {
    width: 22px;
    margin-top: 5px;
  }

  &__item-text {
    width: 70%;
    display: inline-block;
    vertical-align: top;
    font-size: 16px;
    margin-left: 12px;
    margin-top: 5px;
    transition: 0.3s color;

    &--no-hover {
      cursor: default;
      width: 70%;
      display: inline-block;
      vertical-align: top;
      font-size: 16px;
      margin-left: 12px;
      margin-top: 5px;
    }

    &:hover {
      color: #f5a8a8;
    }

    &.selected {
      color: #f5a8a8;
    }
  }
}

.admin-menubar {
  @media (--device) {
    display: none;
  }
}
