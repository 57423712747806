@define-extend payment-button {
  width: 120px;
  height: 120px;
  background-color: #575252;
  color: #fff;
  border: 0;
  cursor: pointer;
  border-radius: 5px;
  margin: 10px;
  vertical-align: top;
}
.alert-message{
  display: none;
  @media (--device) {
    display: block !important;
  }
}
.payment {
  position: relative;
  text-align: center;
  &__title {
    margin: 10px;
  }
  &__subtitle {
    margin: 10px;
    margin-bottom: 20px;
  }
  &__button {
    position: relative;
    @extend payment-button;
    &:disabled {
      opacity: 0.4;
    }
    &-text {
      width: 100%;
      display: block;
    }
    &-icon {
      margin: 10px auto;
      margin-bottom: 2px;
      max-width: 100%;
      max-height: 29px;
      filter: brightness(10) contrast(1);
    }
    &--translucent {
      @extend payment-button;
      background-color: #575252;
    }
    &--active {
      @extend payment-button;
      background-color: $main;
    }
    &-boleto {
      width: 273px;
      height: 53px;
    }
    &-boleto img {
      float: right;
      margin-top: 10px;
      margin-right: 20px;
    }
    &-boleto span {
      margin-left: 14px;
    }
  }
  &__error {
    display: block;
    margin-top: 20px;
    color: #ec6262;
  }
}

.warning-message{
  position: absolute;
  background-color: red;
  width: 100%;
  right: 0;
}
.payment-info {
  max-width: 708px;
  margin: 30px auto;
  text-align: left;
  font-family: 'Lato';
  @media (--device) {
    padding: 0 20px;
  }
  &__title {
    display: block;
    color: #ec6262;
    font-weight: 900;
    font-size: 20px;
    margin-bottom: 13px;
    @media (--device) {
      font-size: 18px;
    }
  }
  &__section {
    margin-bottom: 30px;
    h4 {
      sup {
        color: #59c688;
      }
    }
  }
  &__desc {
    color: #575252;
    font-size: 18px;
    margin: 8px 0;
    @media (--device) {
      font-size: 16px;
    }
  }
}

.checkbox_area {
  display: flex;
  align-items: center;
}

.checkbox {
  width: 19px;
  height: 19px;
  margin-right: 5px !important;
  appearance: none;
  background-color: #fff;
  border: 1px solid #000000 !important;
  border-radius: 3px;
  text-align: left;

  &:checked {
    color: #ffffff;
    border: 0;
    background: url('../components/SelectProducts/images/mark.png') no-repeat center;
    background-size: cover;
  }
  &__label {
    width: 90%;
  }
}

.estimate {
  &__helpicon {
    padding: 5px;
    display: inline;
  }
  &__helpicon:hover &__tooltip {
    display: block;
  }
  &__tooltip {
    display: none;
    float: left;
    left: 430px;
    width: 400px;
    position: absolute;
    background-color: #ffedb8;
    color: #000;
    z-index: 1;
    font-size: 14px;
    border-radius: 5px;
    padding: 15px;
    margin-top: 10px;
    clear: both;

    &__text {
      display: block;
      vertical-align: middle;
      width: 100px;
      font-weight: 700;
      width: 90%;
    }
  }
}

.text-red {
  color: #ec6262;
}

.modal-dialog {
  .modalFooter {
    button {
      &.inline-button {
        width: 50%;
        display: inline-block;
        font-size: 22px;
        padding-top: 18px;
        padding-bottom: 50px;
      }

      &.accept {
        background-color: #47c4f5;
      }

      &.disabled {
        color: #a4a4a4;
        background-color: #7f7f7f;
      }
    }
  }
}
