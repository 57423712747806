.summary {
  &__totalbooks {
    .column {
      width: 50%;
      padding: 10px;
      display: inline-block;
      vertical-align: top;
      text-align: center;
    }
  }
}

.totalbooks {
  &__value {
    display: block;
    margin-top: 10px;
    color: #cccccc;
    font-size: 20px;
    font-weight: 900;
  }

  &__total {
    text-align: center;
    margin-bottom: 30px;
    .totalbooks__value {
      font-size: 36px;
      font-weight: 900;
      color: #eccc62;
    }
  }
}
