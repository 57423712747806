.notification {
  &__select {
    height: 62px;
    float: none;

    &:disabled {
      color: #0000006a;
    }
  }

  &__subtitle {
    display: block;
    margin-top: 5px;
    cursor: pointer;
    text-decoration: underline;
    text-align: center;

    &:hover {
      text-decoration: underline;
    }
  }

  .report__title {
    margin: 35px 0 10px 0;
  }

  .button__small {
    vertical-align: top;
  }

  .button.is_active.active {
    background: #00b050;
  }

  .button.block_checkout.unblocked {
    background: #00b050;
  }

  .export {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  &-form {
    .field__label {
      width: 18% !important;
    }

    .field__submit,
    .field__block_checkout,
    .field__is_active {
      margin-left: 19% !important;
      text-align: left;

      .FormInput {
        width: fit-content;
        margin-right: 1rem;
        position: relative;
        top: 3px;
      }
    }

    .field__submit {
      margin-bottom: 3rem;
    }
  }

  .filtertext__input.test-filtertext {
    width: 360px;
  }
}

.mce-notification-inner {
  display: none !important;
}

.tox-notifications-container {
  display: none !important;
}
