@media(--phone){
  .card{
    display: none;
  }
}
.card-register {
  text-align: center;
  flex-direction: column;
  display: flex;
  width: 100%;
  &__form {
    width: 100%;
    max-width: 480px;
    text-align: right;
  }
  &__content{
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
    gap: 2.2em;
    @media(max-width: 768px){
      gap: 0;
    }
  }
  &__form,
  &__card {
    display: inline-block;
    vertical-align: middle;
  }

  &__card {
    @media (max-width: 1244px) {
      display: none;
    }
  }

  &__label {
    text-align: right;
    width: 110px;
    @media (--phone) {
      text-align: left;
    }
  }

  &__select {
    width: 100%;
    max-width: 358px;
    display: inline-block;
    text-align: left;
  }

  &__field {
    .FormInput {
      max-width: 358px;
    }
  }

  &__container {
    &__field--expiry {
      width: 60%;
      max-width: 280px;
      float: left;
      @media (--phone) {
        width: 100%;
        max-width: 100%;
        margin-bottom: 20px;
      }
      .FormInput {
        max-width: 160px;
        @media (--phone) {
          max-width: 100%;
        }
      }
    }

    &__field--cvv {
      width: 40%;
      max-width: 160px;
      float: right;
      @media (--phone) {
        width: 100%;
        max-width: 100%;
      }
      .FormInput {
        max-width: 120px;
        @media (--phone) {
          max-width: 100%;
        }
      }
    }
  }

  &__footer {
    text-align: center;
    width: 100%;
    max-width: 358px;
    float: right;
    @media (--phone) {
      float: none;
    }
    .field__error {
      max-width: 250px;
      margin: 0 auto;
      margin-top: 10px;
      float: left;
      @media (--phone) {
        float: none;
      }
    }
  }

  &__button {
    float: right;
    @media (--phone) {
      float: none;
    }
  }

  &__title {
    margin-bottom: 25px;
    text-align: center;
    flex: 1;
  }
}
