.select-book {
  text-align: center;
  &__header {
    text-align: center;
    margin-bottom: 50px;
  }

  &__title {
    margin-bottom: 5px;
  }

  &__subtitle {
    span {
      color: $main;
      font-weight: 700;
    }
  }

  &__item {
    width: 162px;
    display: inline-block;
    vertical-align: top;
    margin: 0 40px 40px 0;
    text-align: center;
    @media (--phone) {
      width: 100%;
    }

    &:nth-child(4n) {
      @media (--desktop) {
        margin-right: 0;
      }
    }

    &--deselected {
      opacity: 0.5;
    }
  }

  &__container {
    h4 {
      border-bottom: 1px solid #ccc;
      color: #ec6262;
      font-size: 26px;
      font-weight: 700;
      margin-bottom: 20px;
      padding-bottom: 10px;
      text-align: left;
    }
  }

  &__removed_list {
    max-height: 460px;
    overflow-y: scroll;
    overflow-x: hidden;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    @media (--phone) {
      grid-template-columns: repeat(1, 1fr);
    }
  }


  &__list {
    max-width: 810px;
    margin: 0 auto;
    text-align: center;
    &__cover {
      width: 100%;
      max-width: 89px;
      height: auto;
      margin-bottom: 8px;
    }

    &__image {
      display: inline-block;
      width: 50%;
      max-width: 89px;
      position: relative;
      @media (--phone) {
        width: 30%;
        margin-right: 10px;
      }
    }

    &__text {
      color: $title;
      position: relative;
      @media (--phone) {
        width: 65%;
        max-width: 250px;
        text-align: left;
        display: inline-block;
        vertical-align: top;
      }
    }

    &__title {
      font-size: 14px;
      display: block;
      font-weight: 700;
    }

    &__price {
      &--discount {
        font-size: 14px;
        text-decoration: line-through;
        margin-right: 10px;
      }

      &--full {
        display: block;
        margin-top: 5px;
        font-size: 20px;
        font-weight: 700;
      }
    }

    &__discount-tag {
      /*margin-left: 10px;*/
      color: $success;
      font-size: 14px;
      font-weight: 700;
      display: block;
      @media (--phone) {
        display: inline;
        font-size: 13px;
      }
    }

    &__digital-tag {
      background: #ec6262;
      border-radius: 11px;
      bottom: 0;
      color: #fff;
      font-size: 11px;
      font-weight: 700;
      height: 22px;
      line-height: 19px;
      padding: 1px 5px 1px 1px;
      position: absolute;
      right: 0px;
      text-align: left;
      top: -30px;
      vertical-align: middle;
      width: 95px;

      @media (--phone) {
        display: block;
        position: relative;
        right: 0;
        top: 0;
      }

      .digital-tag-icon {
        background: #fff;
        border-radius: 10px;
        color: #ec6262;
        display: inline-block;
        font-size: 14px;
        height: 20px;
        margin-right: 3px;
        text-align: center;
        width: 25px;
      }
    }

    &__checkbox {
      width: 15px;
      height: 15px;
      appearance: none;
      margin: 0 15px;
      background-color: #ffffff;
      position: absolute;
      border: 1px solid black;
      border-radius: 3px;
      &:checked {
        color: #ffffff;
        border: 0;
        background-image: url('../components/SelectProducts/images/mark.png');

        &.disabled {
          background-image: url('../components/SelectProducts/images/disabled.svg');
        }
      }

      @media (--phone) {
        top: 5px;
        right: 3px;
        margin: 0;
      }
    }
  }
}

.text-red {
  color: #ec6262;
}

.btn_know_the_project {
  margin-left: 15px;
  button {
    background-color: #5796d1;
    font-size: 14px;
    color: #fff;
    border: 1px solid #5796d1;
    transition: all ease 0.3s;
    padding: 2px 10px 4px;
    border-radius: 3px;
    &:hover {
      color: #5796d1;
      background-color: #fff;
    }
  }
  @media (max-width: 767px) {
    width: 100%;
    display: block;
    margin-left: 0;
  }
}
