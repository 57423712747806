.faqhero {
  position: relative;
  height: 100%;
  &__title {
    font-size: 44px;
    margin-bottom: 20px;
  }
  &__image {
    text-align: center;
    max-height: 100%;
    max-width: 100%;
    width: auto;
    height: 180px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    @media (--device) {
      position: relative;
      height: auto;
    }
  }
}
