$itemWidth: 124px;

@define-extend steps__bar {
  position: absolute;
  width: 100%;
  top: 6px;
  left: 50%;
  height: 2px;
  z-index: 1;
  transition: width 0.5s ease-in;
}

@define-extend steps__position {
  position: relative;
  z-index: 2;
  width: 14px;
  height: 14px;
  margin: 0 auto;
  border-radius: 50%;
}

.steps {
  width: 100%;
  background-color: $title;

  &__list {
    max-width: 815px;
    height: 116px;
    padding: 30px 0;
    margin: 0 auto;
    position: relative;
    lost-utility: clearfix;
    & > li {
      lost-column: 1/7 0 0;
    }
    @media (--phone) {
      padding: 47px 0 0 0;
      height: 81px;
    }
  }

  &__book {
    display: none;
    position: absolute;
    top: -7px;
    left: 42%;
    z-index: 2;
  }

  &__bar {
    @extend steps__bar;
    background: #fff;
    &--success {
      @extend steps__bar;
      width: 0;
    }
  }

  &__item {
    position: relative;
    &:last-child {
      .steps__bar {
        display: none;
      }
    }

    &.past {
      @media (--phone) {
        .steps__description {
          display: none;
        }
      }
      .steps__bar {
        @extend steps__bar;
        width: 100%;
        &--success {
          width: 100%;
          background: $success;
        }
      }
    }

    &.current {
      .steps__bar {
        width: 100%;
      }

      .steps__book {
        display: block;
      }

      @media (--phone) {
        .steps__description {
          width: 200%;
          text-align: center;
          top: -40px;
          left: -50%;
        }
      }
    }

    &.future {
      @media (--phone) {
        .steps__description {
          display: none;
        }
      }
      .steps__position {
        background-color: #fff;
      }
    }
  }

  &__position {
    @extend steps__position;
    background-color: $success;
  }

  &__description {
    width: 100%;
    position: absolute;
    top: 40px;
    font-family: 'Lato';
    font-weight: 100;
    font-size: 14px;
    text-align: center;
    text-transform: capitalize;
    color: #fff;
  }
}
