@import '_utilities';
@import 'react-credit-cards/lib/styles-compiled.css';
@import-glob '../components/**/*.css';
@import-glob '../containers/**/*.css';
@import-glob 'shared/*.css';
@import 'react-datepicker.css';

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

body,
html {
  /* height: 100%; */
  min-height: 100%;
  overflow-x: hidden;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Lato';
  overflow-x: hidden;
}

#root,
.app {
  height: 100%;
}

*:focus {
  outline: none;
}

a {
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}

.content {
  max-width: 1366px;
  height: 100%;
  padding: 0 20px;
  position: relative;
  margin: 0 auto;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

p {
  font-size: 18px;
  color: $title;
  font-weight: 400;
}

h1 {
  font-size: 24px;
  font-weight: 700;
  margin: 0;
  color: $title;
  @media (--device) {
    font-size: 20px;
  }
}

h2 {
  font-size: 14px;
  color: $title;
  font-weight: 700;
}

h3 {
  font-size: 32px;
  color: $title;
  font-weight: 700;
  margin: 0;
  @media (--device) {
    font-size: 26px;
  }
}

h4 {
  font-size: 20px;
  color: $title;
  font-weight: 400;
  margin: 0;
  @media (--device) {
    font-size: 18px;
  }
}

