.filterdate {
  overflow: hidden;
  &__field {
    display: inline-block;
    vertical-align: top;
    margin-right: 14px;
    &:last-child {
      margin-right: 0;
    }
  }
}

.inputdate {
  color: $title;
  cursor: pointer;
  &__icon {
    display: inline-block;
    vertical-align: middle;
    margin-right: 8px;
  }
  &__current {
    display: inline-block;
    padding-right: 20px;
    vertical-align: middle;
    position: relative;
    font-size: 14px;
    font-weight: bold;
    &:after {
      content: '';
      width: 10px;
      height: 13px;
      display: block;
      background-image: url('../components/FilterDate/images/arrow.png');
      position: absolute;
      top: 1px;
      right: 0;
    }
  }
  &__label {
    display: inline-block;
    font-size: 14px;
    margin-right: 4px;
  }
}
