.admin {
  width: 100%;
  min-height: 100%;
  height: auto;
  display: inline-block;
  background-color: #fff;
  @media (--device) {
    margin: 0;
  }

  &__left {
    background-color: #fff;
    float: left;
    overflow: hidden;
    vertical-align: top;
    /*height: 100vh;
    min-height: 100%;*/
    width: 20%;
    height: 100%;
    max-width: 220px;
    @media (--device) {
      display: none;
    }
  }

  &__right {
    width: calc(100% - 220px);
    min-height: 100vh;
    height: 100%;
    float: right;
    overflow: hidden;
    vertical-align: top;
    padding: 0 20px;
    background-color: #efefef;
    @media (--device) {
      display: block;
      width: 100%;
      height: auto;
    }
  }
}
