.summary {
  margin: 30px 30px 70px 30px;
  @media (--phone) {
    margin: 10px 0;
  }
  &__header {
    margin-bottom: 40px;
  }
  &__title {
    font-size: 30px;
  }
  &__card {
    padding: 70px 60px;
    width: 100%;
    margin-bottom: 10px;
  }
  &__totalcard {
    padding: 35px 60px;
    margin-bottom: 10px;
  }
  &__cards {
    width: 100%;
    max-width: 420px;
    display: inline-block;
    vertical-align: top;
  }
  &__school-filter {
    background-color: #fff;
    border-radius: 5px;
    width: 460px;
    margin-right: 40px;
    height: 35px;
  }
  &__school-id-filter {
    display: grid;
    margin-top: 1rem;
  }
  &__filter-wrapper {
    height: 100px;
    & .filtertext {
      display: inline-block;
    }
    & .filterdate {
      vertical-align: middle;
      display: inline-block;
      & .inputdate {
        width: 160px;
      }
    }
  }
  &__error {
    font-size: 16px;
    color: #ec6262;
    font-weight: normal;
  }
}
