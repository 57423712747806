.transfer-list-container {
    max-width: 360px;
    font-size: 16px;
    gap: 12px;
    display: flex;
    flex-direction: column;
    width: 360px;
    height: 450px;
    max-height: 450px;
    overflow-y: auto;
    background: #EFEFEF;
    padding-top: 1em;
    border-radius: 12px;
    border: solid 1px #ddd;
    position: relative;
}

.transfer-list-wrapper {
    display: flex;
    gap: 2em;
    justify-content: center;
    margin-top: 2em;
}

@media (max-width: 768px) {
    .transfer-list-wrapper {
        flex-direction: column;
    }

    .transfer-list-container {
        width: 100%;
    }
    .transferlist-description{
        text-align: center;
    }
    .transfer-list-controls {
        flex-direction: row !important;
    }

    .transfer-list-controls>button {
        transform: rotate(90deg);
    }
}

.transfer-list {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 16px;
    border-radius: 12px;
    padding: 0em 1em;
}

.transfer-list-button {
    font-size: 18px;
}

.transfer-list-button:disabled {
    background: #e3e3e3 !important;
    opacity: .2;
}

.transfer-list-name {
    color: #EC6262;
    font-weight: bold;
    font-size: 17px;
    margin-bottom: 1.1em;
}

.transfer-list-footer {
    padding: .7em .7em .7em;
    color: #EC6262;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    border-top: solid 1px #ddd;
    position: sticky;
    bottom: 0;
    background: #EFEFEF;
}

.transfer-list-item {
    display: flex;
    cursor: pointer;
    gap: 16px;
}

.transfer-list-controls {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    justify-content: center;
}

.transfer-list-label {
    display: flex;
    align-items: center;
    gap: 16px;
    color: #575252;
}

.transfer-list-value {
    flex: 1;
    text-align: right;
}

.transfer-list-header {
    display: flex;
    background-color: #ddd;
    padding: .5em 1em;
}

.select-all-items {
    display: flex;
    gap: 16px;
    align-items: center;
    padding: 0em 1em;
}

.select-all-items>input {
    margin: 0;
}

.field__checkbox:disabled{
    background: #ddd;
    opacity: 0.3;
}