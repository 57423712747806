.modal {
  &__overlay {
    z-index: 4;
    position: fixed;
    overflow: hidden;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.7;
  }

  &__content {
    z-index: 5;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50%;
    right: 50%;
    left: 50%;
    overflow: auto;
    margin: auto;
    border-radius: 4px;
    outline: none;
    transform: translate(-50%, -50%);
    @media (--device) {
      top: 50%;
      left: 50%;
      overflow: scroll;
    }
  }
}

/*
Bootstrap modal customization
 */
.modal-dialog {
  .modal-body {
    padding: 0;
  }

  @media (--device) {
    bottom: 0;
    margin: 0;
    padding: 0;
    position: fixed;
  }

  .modalHeader {
    border-bottom: 1px solid #ccc;
    margin-bottom: 20px;
    padding: 15px 15px 0 15px;
    text-align: center;

    .icon-alert {
      background: url('../img/alert.svg') center no-repeat;
      background-size: cover;
      display: block;
      height: 28px;
      margin: 0 auto;
      position: relative;
      width: 34px;
    }

    h1 {
      color: #ec6262;
      font-size: 20px;
      margin: 20px 0;
    }
  }

  .textCenter {
    text-align: center;
  }

  .modalContent {
    padding: 0 15px;

    .yellow-text {
      color: #eccc62;
      font-weight: 700;
    }

    ul {
      padding: 0 20px;

      li {
        margin-bottom: 20px;
        h2 {
          color: #575252;
          display: inline;
          font-size: 20px;
          font-weight: 700;
          margin-top: 0;
        }

        &::before {
          content: '\2022';
          color: #ec6262;
          font-weight: bold;
          display: inline-block;
          width: 1em;
          margin-left: -1em;
        }
      }
    }
  }

  .modalFooter {
    margin-top: 20px;
    position: relative;
    .modal-support-btn {
      background-color: #dbdbdb;;
    }
    button {
      background: #ec6262;
      border: none;
      color: #fff;
      display: block;
      height: 48px;
      position: relative;
      text-align: center;
      width: 100%;
    }
  }
}
