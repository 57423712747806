:root {
  --gray: #c7c7c7;
  --light-gray: rgb(239, 239, 239);
}
.text-disabled {
  color: #c5c5c5;
}
.background-disabled {
  background: #c5c5c5!important;
}

.disabled-step::after {
  background: #c5c5c5!important;
}

.tracking-timeline {
  padding: 1.5em 0;
  @media (--phone) {
    &__steps {
      flex-direction: column;
    }

    &__step-details {
      flex-direction: row;
      gap: 12px;
      justify-content: start;
      align-items: start;
      text-align: left;

    }

    &__step-line {
      /* display: none; */
      width: 2px;
      height: 10px;
      height: 30px;
      background: #333;
      position: relative;
      margin-left: 20px;
      top: 10px;
      position:relative !important;
    }

    &__step {
      flex-direction: column;
    }

    &__step:after{
      content: "";
      position: absolute;
      width: 2px !important;
      height: 100%!important;
      background: #787878;
      top: -40px;
      margin-left: 33px;
      left: 0;
      right: 0;
    }
  }

  

  &__steps {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }


  &__step {
    display: flex;
    align-items: start;
    flex: 1;
    padding: 10px;
    box-sizing: border-box;
    width: 100%;
    position: relative;
  }

  &__step:after{
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    background: #ddd;
    top: 33px;
    left: -50%;
  }

  &__step:first-child::after{
    content:none;
  }

  &__step-line {
    width: 100%;
    height: 2px;
    position: absolute;
    top: 33px;
    right: 0;
    left: 50px;
    /* width: 100%;
    height: 2px;
    background: #333;
    position: absolute;
    top: 20px; */
  }

  &__step-details {
    display: flex;
    text-align: center;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 8rem;
    max-width: 100%;
    width: 100%;
  }
}


.container {
  display: block;
  position: relative;
  margin: 30px;
  max-width: 680px;
  text-align: center;
  box-shadow: none;
  transition: box-shadow 0.1s;
  margin-bottom: 120px;
}


@media (max-width: 768px) {
  .container {
    flex-direction: column;
    display: flex;
    align-items: center;
  }

  .timeline {
    flex-direction: column;
    gap: 12rem;
    align-items: center;
  }

  .timeline li {
    width: auto
  }

  hr.line {
    height: 97%;
    width: 3px;
    background-color: var(--gray);
  }

  .circle>img {
    width: 45px;
    height: 45px;
  }
}

hr.line {
  width: 580px;
  border-bottom: 3px solid var(--gray);
  background: gray;
  position: absolute;
  top: 22px;
  z-index: 0;
}

.timeline {
  display: flex;
  width: auto;
  z-index: 1;
}

.timeline li {
  width: 80px
}

.between {
  justify-content: space-between;
}

.center {
  justify-content: center;
}

.status {
  margin-top: 8px;
  font-size: 14px;
  font-weight: bold;
}

.date {
  margin-top: -10px;
  font-size: 12px;
}

.item {
  margin-bottom: -95px;
  text-align: center;
  position: relative;
  z-index: 1;
}

.circle {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px;
  height: 50px;
  width: 50px;
  border-radius: 100px;
  color: #ffffff;
  background-color: #fff;
  z-index: 1;
  
}