.notification {
  &__select {
    height: 62px;
    float: none;

    &:disabled {
      color: #0000006a;
    }
  }

  &__subtitle {
    display: block;
    margin-top: 5px;
    cursor: pointer;
    text-decoration: underline;
    text-align: center;

    &:hover {
      text-decoration: underline;
    }
  }

  .report__title {
    margin: 35px 0 10px 0;
  }

  .button__small {
    vertical-align: top;
  }

  .button.is_active.active {
    background: #00b050;
  }

  .button.block_checkout.unblocked {
    background: #00b050;
  }

  .export {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  &-form {
    .admin-form__form {
      .field {
        @media (--phone) {
          display: block;
        }

        .FormInput {
          a {
            font-size: 16px;
            margin-top: 10px;
            display: block;
          }
        }

        .admin-form__button {
          display: block;

          @media (--phone) {
            width: 100%;
            margin-left: 0px;
            margin-top: 10px;
          }
        }
      }
    }

    .field__label {
      width: 18% !important;

      @media (--phone) {
        width: 100% !important;
        float: initial;
      }
    }

    .field__submit,
    .field__block_checkout,
    .field__is_active {
      margin-left: 19% !important;
      text-align: left;

      .FormInput {
        width: fit-content;
        margin-right: 1rem;
        position: relative;
        top: 3px;
      }
    }

    .field__submit {
      margin-bottom: 3rem;
    }
  }

  .filtertext__input.test-filtertext {
    width: 360px;
  }
}

.mce-variables-inner {
  display: none !important;
}

.tox-variabless-container {
  display: none !important;
}

/**
* ----------------------------------------------
* Demo styles
* ----------------------------------------------
**/
.accordion {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 2px;
}

.accordion__item + .accordion__item {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.accordion__button {
  background-color: #f4f4f4;
  color: #444;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  text-align: left;
  border: none;
}

.accordion__button:hover {
  background-color: #ddd;
}

.accordion__button:before {
  display: inline-block;
  content: '';
  height: 10px;
  width: 10px;
  margin-right: 12px;
  border-bottom: 2px solid currentColor;
  border-right: 2px solid currentColor;
  transform: rotate(-45deg);
}

.accordion__button[aria-expanded='true']::before,
.accordion__button[aria-selected='true']::before {
  transform: rotate(45deg);
}

[hidden] {
  display: none;
}

.accordion__panel {
  padding: 20px;
  animation: fadein 0.35s ease-in;
}

/* -------------------------------------------------- */
/* ---------------- Animation part ------------------ */
/* -------------------------------------------------- */

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
