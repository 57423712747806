.books-table {
  &__table {
    background-color: #f5f5f5;
    border: none;
    width: 100%;
    overflow-y: scroll;
  }

  table {
    @media (--phone) {
      display: block;
      overflow-y: scroll;
    }
  }

  &__header-row {
    font-size: 16px;
    font-weight: 400;
    height: 50px;
    color: #676363;
  }

  &__body {
    color: #726e6e;
  }

  &__row {
    &:nth-child(even) {
      background-color: f5f5f5;
    }

    &:nth-child(odd) {
      background-color: #e6e6e6;
    }
  }

  &__col {
    text-align: left;
    padding-left: 13px;
  }

  &__cell {
    border: 0;
    border: none;
    color: #575252;
    font-size: 14px;
    padding: 10px;
    vertical-align: top;

    &:nth-child(1) {
      width: 35px;
      padding: 15px 10px 10px;

      img {
        height: 45px;
      }
    }

    &.cancelled {
      position: relative;

      &::after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 50%;
        width: 100%;
        height: 1px;
        background-color: #000;
      }

      * {
        opacity: 0.3;
        font-style: italic;
      }
    }
  }

  .column__amount {
    white-space: nowrap;
  }

  .column__license_code {
    display: block;
    width: 270px;
    padding: 15px 0;
  }

  .column__cancelled_at {
    white-space: nowrap;
  }

  .button__cancel {
    white-space: nowrap;
    font-size: 1.3rem;
    padding: 0.3rem 1.5rem;

    &:disabled {
      background-color: #999;
    }
  }
}
