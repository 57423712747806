.chartbox {
  width: 100%;
  max-width: 460px;
  display: inline-block;
  padding: 20px 20px;
  background: #fff;
  @media (--phone) {
    overflow-x: scroll;
  }
  &__title {
    width: 100%;
    display: inline-block;
    margin-bottom: 20px;
    font-size: 18px;
    text-align: center;
  }
}
.recharts-surface {
  width: 450px;
}
