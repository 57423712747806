.order {
  @media (--tablet) {
    width: 542px;
    margin: 0 auto;
  }

  &__title {
    margin: 20px 10px;
    @media (--device) {
      margin-top: 20px;
    }
  }
}
