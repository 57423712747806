.receipt {
  width: 100%;
  max-width: 360px;
  margin: 70px auto;
  padding: 35px 0 0 0;
  background: #fff;
  position: relative;
  height: 86%;
  overflow-y: auto;
  &.active {
    position: fixed;
    top: 0;
    margin: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-width: 100%;
  }
  &__container {
    width: 100%;
    max-width: 320px;
    margin: 0 auto;
    padding-left: 10px;
    @media print {
      padding: 0;
      max-width: 80%;
    }
  }
  &__close {
    background-color: transparent;
    font-size: 24px;
    color: $title;
    position: absolute;
    top: 10px;
    right: 15px;
    cursor: pointer;
    border: 0;
    @media print {
      display: none;
    }
  }
  &__title {
    display: block;
    color: $main;
    text-align: center;
    font-size: 20px;
    font-weight: 900;
  }
  &__user-info,
  &__order-info {
    padding: 14px 0;
    margin: 16px 0;
    border-top: 1px $title solid;
    border-bottom: 1px $title solid;
    width: 100%;
    @media print {
      display: table;
      margin: 16px 0 30px;
    }
    &__item {
      margin-bottom: 11px;
      @media print {
        display: table-cell;
        padding: 0 10px;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &__books {
    margin-top: 16px;
    width: 100%;
    @media print {
      list-style: inherit;
    }
    &__item {
      font-size: 14px;
      padding-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
      @media print {
        lost-column: 1/3;
      }
    }
  }
  &__print-out {
    cursor: pointer;
    margin-top: 56px;
    line-height: 56px;
    background-color: $main;
    text-align: center;
    font-size: 16px;
    color: #fff;
    position: fixed;
    bottom: 0;
    width: 100%;
    max-width: 360px;
    @media print {
      display: none;
    }
  }
  &__label {
    display: block;
    font-size: 16px;
    font-weight: 900;
    text-transform: uppercase;
    color: $main;
  }
  &__text {
    display: block;
    font-size: 16px;
    text-transform: capitalize;
    color: $title;
  }
}
