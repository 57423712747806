.filterselect {
  &__label {
    float: left;
    margin-top: 4px;
    color: $title;
    font-size: 14px;
    font-weight: 700;
  }
  &__inputwrapper {
    position: relative;
    float: left;
    width: auto;
    margin-left: 5%;
    &:after {
      content: '';
      width: 10px;
      height: 13px;
      display: block;
      background-image: url('../components/FilterDate/images/arrow.png');
      position: absolute;
      top: 3px;
      right: 0;
      z-index: 1;
      cursor: pointer;
    }
  }
  &__select {
    width: 100%;
    position: relative;
    z-index: 2;
    appearance: none;
    border: 0;
    padding-right: 25px;
    background: transparent;
    font-size: 14px;
    color: $title;
    padding-bottom: 3px;
    border-radius: 0;
    border-bottom: 1px $title solid;
    cursor: pointer;
  }
  &__field {
    position: relative;
    overflow: hidden;
  }
}
