.confirm-dialog {
  position: relative;
  background-color: white;
  width: 260px;
  top: 30%;
  margin: 0 auto;

  &__container {
    padding: 20px;
    margin: 0 auto;
  }

  &__message {
    margin-bottom: 50px;
    text-align: center;
    margin: 30px 10px;
    font-size: 20px;
    @media (--device) {
      margin-top: 20px;
      margin-bottom: 60px;
    }
  }
}

