@define-extend category__item {
  color: $title;
  margin-bottom: 12px;
  text-align: right;
  cursor: pointer;
  padding: 0 5px;
  font-size: 20px;
}

.faqlist {
  &__title {
    text-align: center;
    margin-bottom: 30px;
    @media (--device) {
      display: none;
    }
  }

  &__category {
    lost-column: 1/3;
    border-right: 2px $title solid;
    padding-right: 40px;
    @media (--device) {
      max-width: 100%;
      padding: 0;
      margin: 0 auto;
      lost-column: 1/1 0 0;
      border-right: 0;
      border-bottom: 2px $title solid;
    }
    &__item {
      text-align: right;
      @extend category__item;
      @media (--device) {
        text-align: center;
      }
      &.active {
        font-weight: bold;
      }
      &:first-letter {
        text-transform: uppercase;
      }
      &:hover {
        font-weight: bold;
      }
      &:after {
        display: block;
        content: attr(title);
        font-weight: bold;
        height: 0;
        overflow: hidden;
        visibility: hidden;
      }
      &--title {
        color: $main;
        font-size: 24px;
        font-weight: bold;
        cursor: default;
      }
    }
  }

  &__questions {
    lost-column: 2/3;
    @media (--device) {
      float: none;
      text-align: center;
      width: 100%;
      margin: 15px 0 0;
    }
    &__item {
      @extend category__item;
      margin-bottom: 30px;
      text-align: left;
      font-size: 18px;
      & > div {
        font-weight: 700;
        display: inline;
        vertical-align: top;
      }
      &:first-letter {
        text-transform: uppercase;
      }
    }
    &__icon {
      margin-left: 8px;
      @media (--device) {
        margin-left: 0;
      }
    }
  }
}
