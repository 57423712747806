/* variables */
$main: #ec6262;
$title: #575252;
$success: #31e14e;
$lightGray: #dbdbdb;
$checkoutStudent: #efefef;
$checkoutDiscount: #eccc62;
$succeed: #168a29;

@custom-media --phone (width >= 320px) and (width < 768px);
@custom-media --tablet (width >= 768px) and (width <= 992px);
@custom-media --device (width >= 320px) and (width <= 992px);
@custom-media --desktop (width > 992px);

.bg-darkgray {
  background-color: #575252 !important;
}

.bg-lightgray {
  background-color: #dbdbdb !important;
}

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: ' ';
  clear: both;
  height: 0;
}

.mt-15 {
  margin-top: 15px;
}
.mt-10 {
  margin-top: 10px;
}
.mt-5 {
  margin-top: 5px;
}
.d-block {
  display: block;
}

* html .clearfix {
  zoom: 1;
} /* IE6 */
*:first-child + html .clearfix {
  zoom: 1;
} /* IE7 */

.display-none {
  display: none;
}

