@define-extend voucher__insert {
  lost-column: 1/2 0 0;
  position: relative;
}

#root .hero {
  width: 100%;
  height: 417px;
  margin: 0 auto;
  background-image: url('../components/Voucher/images/voucher_bg.jpg');
  background-position: top;
  background-repeat: repeat-x;
  position: relative;
  @media (--device) {
    padding: 40px 0 0 0;
    background-image: url('../components/Voucher/images/m_voucher_bg.jpg');
    height: auto;
  }
  &:after {
    content: '';
    background-image: url('../components/Voucher/images/street.png');
    display: block;
    width: 100%;
    height: 4px;
    position: absolute;
    bottom: 0;
    left: 0;
    @media (--device) {
      bottom: 3px;
    }
  }

  .bus {
    height: 110px;
    position: absolute;
    bottom: 0;
    left: -220px;

    @media (--device) {
      display: none;
    }
    &.active {
      transition: 3s left ease-out;
      left: 60px;
    }
  }

  .traffic-light {
    height: 154px;
    position: absolute;
    bottom: 0;
    left: 5px;
    @media (--device) {
      display: none;
    }
  }

  &__school {
    width: 394px;
    height: 222px;
    position: absolute;
    bottom: 2px;
    right: 5px;
    @media (--device) {
      width: 280px;
      height: auto;
      position: relative;
      bottom: auto;
      right: auto;
      margin: 0 auto;
      img {
        width: 100%;
        height: auto;
      }
    }
  }

  &__cloud {
    position: absolute;
    z-index: 0;
    animation-name: cloud-animate;
    animation-duration: 2s;
    animation-timing-function: linear;
    animation-delay: 0s;
    animation-iteration-count: infinite;
    animation-direction: alternate;

    &:nth-child(1) {
      top: 62px;
      left: 60px;
      @media (--phone) {
        top: 120px;
        left: 10px;
      }
    }
    &:nth-child(2) {
      top: 170px;
      left: 181px;
    }
    &:nth-child(3) {
      top: 220px;
      left: 52px;
      @media (--phone) {
        left: auto;
        right: 5px;
      }
    }
    &:nth-child(4) {
      top: 36px;
      right: 75px;
      @media (--phone) {
        display: none;
      }
    }
    &:nth-child(5) {
      top: 99px;
      right: 260px;
      @media (--phone) {
        display: none;
      }
    }
    &:nth-child(6) {
      top: 113px;
      right: 124px;
      @media (--phone) {
        display: none;
      }
    }
  }
}

@keyframes cloud-animate {
  from {
    transform: matrix(1, 0, 0, 1, -10, -10);
  }
  to {
    transform: matrix(1, 0, 0, 1, 2, 4);
  }
}
