.pix-modal .modal-dialog {
  max-width: 420px;
}

.pix-content {
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pix-modal p {
  font-size: 12pt;
}

.pix-timer {
  padding: 25px;
}

.pix-timer span {
  border: 1px solid dimgray;
  border-radius: 3px;
  padding: 5px 12px 7px;
  font-size: 12pt;
  color: dimgray;
  font-weight: 600;
}

.pix-modal .pix-logo {
  margin: -65px 0px -50px;
  width: 175px;
}

.pix-copy-paste {
  border: 1px solid dimgray;
  border-radius: 8px;
  padding: 15px 5px;
  max-width: 100%;
  display: flex;
  justify-content: space-around;
  cursor: pointer;
  margin: 10px 0px;
  background-color: white;
}

.pix-copy-paste :first-child {
  width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 600;
  white-space: nowrap;
}

.pix-copy-paste :last-child {
  color: dodgerblue;
}

.pix-content>span {
  font-weight: 600;
  margin-top: 5px;
}

.pix-modal .modal-footer {
  display: flex;
  justify-content: space-around;
  font-size: 12pt;
  font-weight: 600;
  padding: 10px;
}

.pix-expired-info {
  margin: 25px 0px 60px;
  text-align: justify;
}

@media (min-width: 100px) and (max-width: 992px) {
  .modal-dialog {
    bottom: 0;
    margin: 0 auto !important;
    padding: 0;
    position: relative !important;
  }
}