.vouchers_inactivation {
  max-width: 960px;
  &_content {
    padding: 15px;
    background: #fff;
    border-radius: 10px;
  }
  &_subtitle {
    color: #ec6262;
    font-size: 24px;
    font-family: inherit;
    margin-bottom: 20px;
  }
  &_description {
    font-size: 16px;
    line-height: 1.42857143;
    color: #333;
  }
  &_form {
    padding-top: 20px;
  }
  &_checkbox {
    display: flex;
    align-items: center;
    label {
      font-size: 16px;
      margin: 5px 5px 0;
    }
  }
  &_footer {
    display: flex;
    justify-content: flex-end;
    button {
      float: inherit;
    }
  }
}
.vouchers_inactivation_modal {
  display: flex;
  padding: 30px 0;
  align-items: center;
  justify-content: center;
  animation: fade__down 0.3s;
  &_content {
    background-color: #fff;
    max-width: 100%;
    width: 500px;
    -webkit-box-shadow: 0 5px 15px rgb(0 0 0 / 50%);
    box-shadow: 0 5px 15px rgb(0 0 0 / 50%);
    border-radius: 6px;
    .vouchers_inactivation_modal_header {
      border-bottom: 1px solid #ccc;
      margin-bottom: 20px;
      padding: 15px 15px 0 15px;
      text-align: center;
      .icon {
        background-size: cover;
        display: block;
        height: 28px;
        margin: 0 auto;
        position: relative;
        width: 34px;
      }
      .vouchers_inactivation_modal_title {
        color: #ec6262;
        font-size: 20px;
        margin: 20px 0;
      }
    }
    .vouchers_inactivation_modal_main {
      padding: 15px;
    }
    .vouchers_inactivation_modal_footer {
      margin-top: 20px;
      position: relative;
      .vouchers_inactivation_modal_close {
        background: #ec6262;
        border: none;
        color: #fff;
        display: block;
        height: 48px;
        border-radius: 0 0 6px 6px;
        position: relative;
        text-align: center;
        width: 100%;
      }
    }
  }
}

@keyframes fade__down {
  from {
    opacity: 0;
    transform: translateY(-30px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}
